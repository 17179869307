<template>
  <div class="card header mt-3">
      <h2>Quotation / ใบเสนอราคา</h2>  
      <div class="col-11 ms-4">
        <h5>ชื่อบริษัท</h5>
        <label for="choices-company">ชื่อบริษัท</label>
        <select id="choices-company" class="form-control" name="choices-company">
          <option value="choice1">1</option>
          <option value="choice2">2</option>
          <option value="choice3">3</option>
          <option value="choice4">4</option>
          <option value="choice5">5</option>
        </select>        
        <p class="styled-paragraph">
          89/216 หมู่บ้าน คาซ่าซิตี้วัชรพล -เพิ่มสิน 2 แขวงสายไหม เขตสายไหม กรุงเทพมหานคร 10220 
          <br>โทร 0-2509-2121 แฟกซ์ 0-2509-2122
          <br>89/216 Casa City Watcharapol - Permsin 2, SAIMAI, Bangkok 10220
          <br>TEL.0-2509-2121 
          <br>FAX 0-2509-2122
          <br>เลขประจำตัวผู้เสียภาษี : 0-1055-67073-40-1
    </p>        
      </div>  
      <div class="container">
        <h5>ชื่อ Co-worker</h5>
        <div class="col-lg-4 d-flex align-items-center">
        <MaterialInput
            class="input-group-dynamic mb-4 col-6"
            icon="search"
            type="text"
            placeholder="Search"
        />
        <material-button class="btn btn-info ms-3 mb-4 col-6">Select</material-button>
        <material-button class="btn btn-success ms-3 mb-4 col-6"><i class="material-icons text-white position-relative text-md pe-2"
          >add</i>Add Co-Worker</material-button>
        </div>
        <p class="styled-paragraph">
          บริษัท ไทยเทรด แอนด์ บิวท์ จำกัด
          <br>คุณ สัมพันธ์ บัวจะมะ
          <br>เลขที่ 3/227 ถนนเทศบาลนิมิตรเหนือ แขวงลาดยาว เขตจตุจักร กทม
      </p>
        <p>วันที่ออกใบ</p>
          <div class="mb-3 col-4">
            <material-input type="date" variant="dynamic"  />
      </div>
      </div>  
  <div class="table-responsive">
  <table class="table table-flush">
    <thead class="thead-light">
      <tr>
        <th>ลำดับ</th>
        <th>รายการ</th>
        <th>ราคา</th>
        <th>จำนวน</th>
        <th>หน่วย</th>
        <th>ราคารวม</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in rows" :key="index" class="text-sm font-weight-normal">
        <td><input v-model="row.num" type="text" /></td>
        <td><input v-model="row.list" type="text" /></td>
        <td><input v-model.number="row.quantity" type="number" placeholder="จำนวน" /></td>
        <td><input v-model.number="row.price" type="number" step="any" placeholder="ราคา" /></td>
        <td><input v-model="row.unit" type="text" /></td>
        <td>{{ row.quantity * row.price }}</td>
        <td>
          <button @click="addRow" class="btn bg-gradient-success"><i class="material-icons text-white position-relative text-md ">add</i></button>
          <button @click="removeRow(index)" class="btn bg-gradient-danger ms-3"><i class="material-icons text-white position-relative text-md ">delete</i></button>
        </td>
      </tr>
      <tr>
        <td colspan="4"></td>
        <td>ราคารวม</td>
        <td>{{ total }}</td>
      </tr>
      <tr>
        <td colspan="4"></td>
        <td>ส่วนลด</td>
        <td><input v-model.number="form.qo_discount" type="number" placeholder="ส่วนลด" /></td>
      </tr>
      <tr>
        <td colspan="4"></td>
        <td>ราคารวมทั้งหมด</td>
        <td>{{ total_discount }}</td>
      </tr>
      <tr>
        <td colspan="4"></td>
        <td>ภาษีมูลค่าเพิ่ม/V.A.T 7%</td>
        <td>{{ total_VAT }}</td>
      </tr>
      <tr>
        <td colspan="4"></td>
        <td>ราคาสุทธิ</td>
        <td>{{ total_sum }}</td>
      </tr>
    </tbody>
  </table>
</div>
<h6 class="d-flex align-items-center col-7" >หมายเหตุ :</h6>
                  <material-textarea
                    id="information"
                    variant="dynamic"
                    placeholder="Say a few words about who you are or what you're working on."
                  />
                  <div class="d-flex align-items-center"><h6 >ระยะเวลาใบเสนอราคา : </h6>
    <div class="col-2 ">
    <select id="choices-time" class="form-control" name="choices-time">
      <option value="choice1">30</option>
      <option value="choice2">60</option>
      <option value="choice3">90</option>
      <option value="choice4">120</option>   
    </select>
  </div>
  <h6 > วันนับจากวันเสนอราคา</h6>
</div>
<div class="d-flex align-items-center"><h6 >การรับประกันการติดตั้ง : </h6>
    <div class="col-2">
    <select id="choices-year" class="form-control" name="choices-year">
      <option value="choice1">1</option>
      <option value="choice1">2</option>
      <option value="choice1">3</option>
      <option value="choice1">4</option>
      <option value="choice1">5</option>
    </select>
  </div>
  <h6 > ปี</h6>
  <div class="col-2">
    <select id="choices-month" class="form-control" name="choices-month">
      <option value="choice1">1</option>
      <option value="choice1">2</option>
      <option value="choice1">3</option>
      <option value="choice1">4</option>
      <option value="choice1">5</option>
      <option value="choice1">6</option>
      <option value="choice1">7</option>
      <option value="choice1">8</option>
      <option value="choice1">9</option>
      <option value="choice1">10</option>
      <option value="choice1">11</option>
      <option value="choice1">12</option>
    </select>
  </div>
  <h6 > เดือน</h6>
</div>
<div class="table-responsive">
  <table id="datatable-search2" class="table table-flush">
    <thead class="thead-light">
      <tr>
        <th>เงื่อนไขการชำระเงิน</th>
        <th>จำนวน</th>
        <th>รายละเอียด</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(condition, index) in paymentConditions" :key="index">
        <td></td>
        <td class="text-sm font-weight-normal">
          <input v-model="condition.mount" class="inline-input mx-2 col-4" style="width: auto;" placeholder="30%" />
        </td>
        <td class="text-sm font-weight-normal">
          <input v-model="condition.des" class="inline-input mx-2 col-4" style="width: auto;" placeholder="หลังตกลงสั่งจ้างและส่งแบบการติดตั้ง" />
        </td>
        <td class="text-sm font-weight-normal">
          <button @click="addRow1" class="btn bg-gradient-success"><i class="material-icons text-white position-relative text-md ">add</i></button>
          <button @click="removeRow1(index)" class="btn bg-gradient-danger ms-3"><i class="material-icons text-white position-relative text-md ">delete</i></button>

        </td>
      </tr>
    </tbody>
  </table>
</div>

    <!-- <p>บริษัทหวังเป็นอย่างยิ่งว่าจะได้รับการพิจารณาจากท่านในเร็ววัน และขอขอบคุณมา ณ ที่นี้.</p> -->
    <div style="display: flex; align-items: center;">
  <p style="margin-right: 10px;">ข้อมูลผู้ที่จะติดต่อในการดำเนินการ</p>
  <div class="col-4 " >
    <select id="choices-member" class="form-control" name="choices-member">
      <option value="choice1">1</option>
      <option value="choice2">2</option>
      <option value="choice3">3</option>
      <option value="choice4">4</option>
      <option value="choice5">5</option>
    </select>
  </div>
</div>

    <p><strong>ในนามบริษัท ซัซ เอ็นจิ้น เทค จำกัด</strong></p>
    <p><strong>ผู้มีอำนาจลงนาม / AUTHORIZED SIGNATURE</strong></p>
    <div class="col-4">
    <select id="choices-members" class="form-control" name="choices-members" placeholder="ผู้มีอำนาจลงนาม / AUTHORIZED SIGNATURE">
      <option value="choice1">1</option>
      <option value="choice2">2</option>
      <option value="choice3">3</option>
      <option value="choice4">4</option>
      <option value="choice5">5</option>
    </select>
  </div>
  <div>
  <material-button
          color="success"
          variant="gradient"
          class="mb-3 ms-auto"
        >Submit</material-button>
  <material-button
          color="danger"
          variant="gradient"
          class="mb-3 ms-3"
        >Cancle</material-button>
      </div>

</div>
      
</template>
<script>
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialButton from "/src/components/MaterialButton.vue";
  import MaterialTextarea from "@/components/MaterialTextarea.vue";
  import * as Choices from "choices.js";
export default {
  components: {MaterialInput,MaterialButton,MaterialTextarea


  },
  data() {
    return {
      rows: [{ num: 1, list: '', quantity: 0, price: 0, unit: '', total: 0 }],
      form: {
        qo_discount: 0
      },
      selectedOption: '',
      paymentConditions: [
        { mount: 0, des: '' },
      ],
      totalMount: 0, // เก็บค่ารวมของ mount
    };
  },
  computed: {
    total() {
      return this.rows.reduce((acc, row) => acc + row.quantity * row.price, 0);
    },
    total_discount() {
      return this.total - this.form.qo_discount;
    },
    total_VAT() {
      return this.total_discount * 0.07;
    },
    total_sum() {
      return this.total_discount + this.total_VAT;
    }
  },
  methods: {
     // ฟังก์ชันคำนวณเปอร์เซ็นต์รวม
     calculateTotalMount() {
      this.totalMount = this.paymentConditions.reduce((sum, condition) => sum + parseFloat(condition.mount || 0), 0);
    },
    
    // ฟังก์ชันสำหรับเพิ่มบรรทัดใหม่
    addRow1() {
      // ตรวจสอบว่าเปอร์เซ็นต์รวมไม่เกิน 100
      this.calculateTotalMount();
      if (this.totalMount < 100) {
        this.paymentConditions.push({ mount: 0, des: '' });
      } else {
        alert("ไม่สามารถเพิ่มได้ เนื่องจากเปอร์เซ็นต์รวมเกิน 100%");
      }
    },
    
    // ฟังก์ชันสำหรับลบบรรทัด
    removeRow1(index) {
      if (this.paymentConditions.length > 1) {
        this.paymentConditions.splice(index, 1);
        this.calculateTotalMount(); // อัปเดตค่ารวมหลังจากลบ
      }
    },
    
    // ฟังก์ชันสำหรับตรวจสอบค่า mount เมื่อมีการแก้ไข
    updateMount() {
      this.calculateTotalMount();
      if (this.totalMount > 100) {
        alert("เปอร์เซ็นต์รวมเกิน 100%");
      }
    },
  addRow() {
    const nextNum = this.rows.length + 1;
    // เพิ่มบรรทัดใหม่ในตาราง rows
    this.rows.push({ num: nextNum, list: '', quantity: 0, price: 0, unit: '', total: 0 });
  },
  removeRow(index) {
    // ลบบรรทัดในตาราง rows ถ้ามีมากกว่า 1 บรรทัด
    if (this.rows.length > 1) {
      this.rows.splice(index, 1);
    }
    }
  },
  watch: {
    // ตรวจสอบเมื่อค่า mount เปลี่ยนแปลง
    'paymentConditions': {
      handler: 'updateMount',
      deep: true
    }
  },
  mounted() {
      const initializeChoices = (id) => {
        const element = document.getElementById(id);
        if (element) {
          new Choices(element, { allowHTML: true });
        }
      };
      initializeChoices("choices-company");
      initializeChoices("choices-member");
      initializeChoices("choices-month");
      initializeChoices("choices-year");
      initializeChoices("choices-time");
      initializeChoices("choices-members");
    },
}
</script>
