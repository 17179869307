<template>
      <div class="container-fluid">
        <div
          class="page-header border-radius-xl mt-7"
        >
        </div>
        <div class="card card-body mx-3 mx-md-4 mt-n6">
       
            <div class="col-auto">
            </div>
            <div class="col-auto my-auto">
                <h5 class="mb-1">Project Name</h5>
                <p class="mb-0 font-weight-normal text-sm">description</p>
          
      <div class="py-4 container-fluid">
      <div class="row">
        <default-statistics-card
          title="Sum of Receipts"
          count="$230,220"
          :percentage="{ color: 'success' }"
          menu="6 May - 7 May"
          :dropdown="dropdownOptions"
        />
        <default-statistics-card
          title="Expense"
          count="3,200"
          :percentage="{ color: 'success'}"
          menu="9 June - 12 June"
          :dropdown="dropdownOptions"
        />
        <default-statistics-card
          title="Team"
          count="6"
          :percentage="{ color: 'secondary' }"
          menu="6 August - 9 August"
          :dropdown="dropdownOptions"
        />
     
            
        </div>
      <div class="py-4 container-fluid">
        <div class="row"> 
        <div class="col-lg-5">
          <div class="card mt-4" data-animation="true">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <a class="d-block blur-shadow-image">
                <img
                  :src="imageUrl"
                  alt="img-blur-shadow"
                  class="img-fluid shadow border-radius-lg"
                />
              </a>
             </div>
          </div>
        </div>
      </div>
          </div>
      
      </div>
      <div class="card card-body mx-3 mx-md-4 ">
      <div class="mt-5 col-lg-12 mt-lg-0">
            <div class="card-body">
              <h6 class="font-weight-bolder">Document</h6>
              <div class="col-lg-3 col-sm-2 mt-sm-0 ">
            <default-info-card
              icon="examples/Icon/Document.vue"
              title="Document1"
              description="Belong Interactive"
            />
          </div>
         </div>
      </div>
      </div>
    </div>
        </div>
        </div>
      <div class="card">
          <div class="row">
            <div class="mt-2 row">
              <div class="col-12 col-md-6 col-xl-7 position-relative">
                <p></p>
                <ul class="information">
                  <li><strong>Type : </strong>Job/company</li>
                  <li><strong> Information : </strong> Hi, Im Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)</li>
                  <li><strong>Start Date : </strong> 20 / 03 / 2024 </li>
                  <li><strong>Due Date : </strong> 20 / 03 / 2024 </li>
                  <li><strong>price : </strong> 1234567 </li>
                  <li><strong>Status : </strong> doing</li>
                  <li><strong>Address : </strong> 17/4 Moo.5, Bamroongrat Road, Tambon Pibulsongkram, Amphur Muang, Bangkok, 10400 </li>

                </ul>
                
                <hr class="vertical dark" />
              </div>
              
              <div class="mt-4 col-8 col-xl-4 mt-xl-0">
            <div class="card card-plain h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Participants</h6>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group">
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="sophie"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <a class="mb-0 text-sm" href="/memberdetail" >Sophie B.</a>
                      <p>director</p>
                    </div>
                  
                  </li>
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="marie"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <a class="mb-0 text-sm" href="/memberdetail" >Anne Marie</a>
                      <p>director</p>
                    </div>
                  
                  </li>
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="ivana"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <a class="mb-0 text-sm" href="/memberdetail" >Ivanna</a>
                      <p>Manager</p>
                    </div>
                    
                  </li>
                  <li
                    class="px-0 mb-2 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="peterson"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                    >
                      <a class="mb-0 text-sm" href="/memberdetail" >Peterson</a>
                      <p>Accountant</p>
                    </div>
                    
                  </li>
                  <li
                    class="px-0 border-0 list-group-item d-flex align-items-center"
                  >
                    <material-avatar
                      class="me-3"
                      :img="nick"
                      alt="kal"
                      border-radius="lg"
                      shadow="regular"
                    />
                    <div
                      class="d-flex align-items-start flex-column justify-content-center"
                      >
                      <a href="/memberdetail" class="mb-0 text-sm">Nick Daniel </a>
                      <p>Staff</p>
          
                    </div>
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="py-4 container-fluid">
        <div class="row gx-4">
          <div class="mt-4 col-lg-12 mt-lg-0">
            <a href="/adminpage/updatetimeline"><material-botton class="mb-3 btn btn-info" ><i class="material-icons text-white position-relative text-md pe-2">add</i>Add Timeline</material-botton></a>
            <a href="/adminpage/purchesproject"><material-botton class="mb-3 ms-2 btn btn-info" ><i class="material-icons text-white position-relative text-md pe-2">add</i>Add Perches</material-botton></a>
            <a href="/adminpage/documentreceipt"><material-botton class="mb-3 ms-2 btn btn-info" >Document Receipt</material-botton></a>
            <a href="/adminpage/editdetailproject"><material-botton class="mb-3 ms-2 btn btn-info" ><i class="material-icons text-white position-relative text-md pe-2">edit</i>Edit</material-botton></a>
            <a href="/adminpage/settingproject"><material-botton class="mb-3 ms-2 btn btn-info" >Setting Project</material-botton></a>

            <!-- Button trigger modal -->
        <button class="mb-3 ms-2 btn btn-danger"
        variant="gradient"
        color="success"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal">
        <i class="material-icons text-white position-relative text-md pe-2">delete</i>
          Delete
        </button>

            <!-- Modal -->
            <div class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirm For Delete</h5>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    Are you sure you want to delete this data? This action cannot be undone.
                  </div>
                  <div class="modal-footer justify-content-between">
                    <button type="button"
                            class="btn btn-dark"
                            data-bs-dismiss="modal">
                      Cancle
                    </button>
                    <button type="button"
                            class="btn btn-danger">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
            <label>
            <input type="radio" v-model="selectedOption" value="option1"> Timeline 
        </label><br>
        
        <label>
            <input type="radio" v-model="selectedOption" value="option2"> Problems Timeline
        </label><br>
        
        <label>
            <input type="radio" v-model="selectedOption" value="option3"> Receipt-Expenses Timeline
        </label><br>

        <label>
            <input type="radio" v-model="selectedOption" value="option4"> In Process Timeline 
        </label><br>

        <!-- Display Content based on selection -->
        <div v-if="selectedOption === 'option1'">
          <timeline-list title="Timeline">
              <timeline-item
                color="success"
                :icon="{ component: 'notifications', class: 'text-white text-sm' }"
                title="ประกาศ"
                date-time="1 JUN 2024"
                description="ให้บุคลากร ที่ทำงานปฎิบัติ."
              />
              <TimelineItem
                color="danger"
                :icon="{ component: 'code', class: 'text-white text-sm' }"
                title="รายงานผล"
                date-time="30 FEB 2024"
                description="ติดตั้งเสาไฟ เหลือ ติดตั้งสาย."
                :badges="['order', '#1832412']"
              />
              <TimelineItem
                color="info"
                :icon="{ component: 'inventory_2', class: 'text-white text-sm' }"
                title="ใช้อุปกรณ์"
                date-time="20 FEB 2024"
                description="อุปกรณที่ใช้งานในพื้นที่."
                :badges="['server', 'payments']"
              />
              <TimelineItem
                color="warning"
                :icon="{ component: 'done', class: 'text-white text-sm' }"
                title="ส่งงาน"
                date-time="15 FEB 2024"
                description="ส่งงาน ที่สำเร็จ เรียบร้อยแล้ว."
                :badges="['Card', '#4395133', 'Priority']"
              />
              <TimelineItem
                color="primary"
                :icon="{ component: 'mail', class: 'text-white text-sm' }"
                title="แจ้งการทำงาน"
                date-time="2 FEB 2024"
                description="ติดตั้งไฟแสงสว่าง ที่เสาหน้าบ้าน."
                :badges="['Development']"
              />
            </timeline-list>
           
        </div>
        <div v-if="selectedOption === 'option2'">
          <timeline-list title="Problems Timeline">
              <timeline-item
                color="success"
                :icon="{ component: 'notifications', class: 'text-white text-sm' }"
                title="ประกาศ"
                date-time="1 JUN 2024"
                description="ให้บุคลากร ที่ทำงานปฎิบัติ."
              />
              <TimelineItem
                color="danger"
                :icon="{ component: 'code', class: 'text-white text-sm' }"
                title="รายงานผล"
                date-time="30 FEB 2024"
                description="ติดตั้งเสาไฟ เหลือ ติดตั้งสาย."
                :badges="['order', '#1832412']"
              />
              <TimelineItem
                color="info"
                :icon="{ component: 'inventory_2', class: 'text-white text-sm' }"
                title="ใช้อุปกรณ์"
                date-time="20 FEB 2024"
                description="อุปกรณที่ใช้งานในพื้นที่."
                :badges="['server', 'payments']"
              />
              <TimelineItem
                color="warning"
                :icon="{ component: 'done', class: 'text-white text-sm' }"
                title="ส่งงาน"
                date-time="15 FEB 2024"
                description="ส่งงาน ที่สำเร็จ เรียบร้อยแล้ว."
                :badges="['Card', '#4395133', 'Priority']"
              />
              <TimelineItem
                color="primary"
                :icon="{ component: 'mail', class: 'text-white text-sm' }"
                title="แจ้งการทำงาน"
                date-time="2 FEB 2024"
                description="ติดตั้งไฟแสงสว่าง ที่เสาหน้าบ้าน."
                :badges="['Development']"
              />
            </timeline-list>
        </div>
        <div v-if="selectedOption === 'option3'">
          <timeline-list title="Receipt-Expenses Timeline">
              <timeline-item
                color="success"
                :icon="{ component: 'notifications', class: 'text-white text-sm' }"
                title="ประกาศ"
                date-time="1 JUN 2024"
                description="ให้บุคลากร ที่ทำงานปฎิบัติ."
              />
              <TimelineItem
                color="danger"
                :icon="{ component: 'code', class: 'text-white text-sm' }"
                title="รายงานผล"
                date-time="30 FEB 2024"
                description="ติดตั้งเสาไฟ เหลือ ติดตั้งสาย."
                :badges="['order', '#1832412']"
              />
              <TimelineItem
                color="info"
                :icon="{ component: 'inventory_2', class: 'text-white text-sm' }"
                title="ใช้อุปกรณ์"
                date-time="20 FEB 2024"
                description="อุปกรณที่ใช้งานในพื้นที่."
                :badges="['server', 'payments']"
              />
              <TimelineItem
                color="warning"
                :icon="{ component: 'done', class: 'text-white text-sm' }"
                title="ส่งงาน"
                date-time="15 FEB 2024"
                description="ส่งงาน ที่สำเร็จ เรียบร้อยแล้ว."
                :badges="['Card', '#4395133', 'Priority']"
              />
              <TimelineItem
                color="primary"
                :icon="{ component: 'mail', class: 'text-white text-sm' }"
                title="แจ้งการทำงาน"
                date-time="2 FEB 2024"
                description="ติดตั้งไฟแสงสว่าง ที่เสาหน้าบ้าน."
                :badges="['Development']"
              />
            </timeline-list>
        </div>
        <div v-if="selectedOption === 'option4'">
          <timeline-list title="In Process Timeline">
              <timeline-item
                color="success"
                :icon="{ component: 'notifications', class: 'text-white text-sm' }"
                title="ประกาศ"
                date-time="1 JUN 2024"
                description="ให้บุคลากร ที่ทำงานปฎิบัติ."
              />
              <TimelineItem
                color="danger"
                :icon="{ component: 'code', class: 'text-white text-sm' }"
                title="รายงานผล"
                date-time="30 FEB 2024"
                description="ติดตั้งเสาไฟ เหลือ ติดตั้งสาย."
                :badges="['order', '#1832412']"
              />
              <TimelineItem
                color="info"
                :icon="{ component: 'inventory_2', class: 'text-white text-sm' }"
                title="ใช้อุปกรณ์"
                date-time="20 FEB 2024"
                description="อุปกรณที่ใช้งานในพื้นที่."
                :badges="['server', 'payments']"
              />
              <TimelineItem
                color="warning"
                :icon="{ component: 'done', class: 'text-white text-sm' }"
                title="ส่งงาน"
                date-time="15 FEB 2024"
                description="ส่งงาน ที่สำเร็จ เรียบร้อยแล้ว."
                :badges="['Card', '#4395133', 'Priority']"
              />
              <TimelineItem
                color="primary"
                :icon="{ component: 'mail', class: 'text-white text-sm' }"
                title="แจ้งการทำงาน"
                date-time="2 FEB 2024"
                description="ติดตั้งไฟแสงสว่าง ที่เสาหน้าบ้าน."
                :badges="['Development']"
              />
            </timeline-list>
          </div>
        </div>
      </div>
    </div>
   </div>
  </div>

  
        
    </template>
    
    <script>
    import MaterialBotton from "/src/components/MaterialButton.vue";
    import sophie from "@/assets/img/kal-visuals-square.jpg";
    import marie from "@/assets/img/marie.jpg";
    import ivana from "@/assets/img/ivana-square.jpg";
    import peterson from "@/assets/img/team-4.jpg";
    import nick from "@/assets/img/team-3.jpg";
    import img1 from "@/assets/img/home-decor-1.jpg";
    import img2 from "@/assets/img/home-decor-2.jpg";
    import img3 from "@/assets/img/home-decor-3.jpg";
    import team1 from "/src/assets/img/team-1.jpg";
    import team2 from "/src/assets/img/team-2.jpg";
    import team3 from "/src/assets//img/team-3.jpg";
    import team4 from "/src/assets/img/team-4.jpg";
    import DefaultStatisticsCard from "/src/views/dashboards/components/DefaultStatisticsCard.vue";
    import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
    import setNavPills from "@/assets/js/nav-pills.js";
    import setTooltip from "@/assets/js/tooltip.js";
    import Timeline from "/src/views/pages/projects/Timeline.vue";
    import TimelineList from "@/examples/Cards/TimelineList.vue";
    import TimelineItem from "@/examples/Cards/TimelineItem.vue";
    import DefaultInfoCard from "/src/examples/Cards/DefaultInfoCard.vue";
    import MaterialAvatar from "/src/components/MaterialAvatar.vue";
    export default {
      name: "ProfileOverview",
      components: {

        TimelineList, 
        TimelineItem,
        DefaultStatisticsCard,
        MaterialBotton,
        DefaultInfoCard,
        MaterialAvatar,
        
      },
      data() {
        return {
          showMenu: false,
          sophie,
          marie,
          ivana,
          peterson,
          nick,
          img1,
          team1,
          team2,
          team3,
          team4,
          img2,
          img3,
          Timeline,
          simplepic,
          selectedOption: '',
          imageUrl: require("@/assets/img/products/product-11.jpg"),
      
    
        };
      },
    
      mounted() {
    
        setNavPills();
        setTooltip(this.$store.state.bootstrap);
      },
    };
    </script> 
    <style scoped>
    .img {
     max-width: 100%;
     height: auto;
    }
    .col-12 col-md-6 col-xl-4 mt-md-0 mt-4  {
      text-align: left;
      margin-left: 10;
    }
    .information{
      font-size: 14px;
      color: #555454;
      margin: 30px 0;
      line-height: 30px;
    }
    </style>
    