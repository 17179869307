<template>
  <h4>Edit Member</h4>
  

    <!-- Upload Section -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card p-3">
          <div
            id="productImg"
            action="/file-upload"
            class="form-control border dropzone"
          ></div>
         
        </div>
      </div>
    </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Information</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <material-input
            id="firstName"
            variant="static"
            label="First Name"
            placeholder="Alec"
          />
        </div>
        <div class="col-6">
          <material-input
            id="lastName"
            variant="static"
            label="Last Name"
            placeholder="Thompson"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <material-input
            id="firstNameTH"
            variant="static"
            label="ชื่อจริง"
            placeholder="เปมิศา"
          />
        </div>
        <div class="col-6">
          <material-input
            id="lastNameTH"
            variant="static"
            label="นามสกุล"
            placeholder="ไตรสุวรรณ์"
          />
        </div>
        <div class="col-6">
          <material-input
            id="nickname"
            variant="static"
            label="Nickname"
            placeholder="Butter"
          />
        </div>
        <div class="col-6">
          <material-input
            id="nicknameTH"
            variant="static"
            label="ชื่อเล่น"
            placeholder="เนย"
          />
        </div>
      </div>
      <div class="col-6">
          <material-input
            id="identiityNo"
            type="text"
            variant="static"
            label="Identity card no"
            placeholder="1139677854356"
          />
        </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-4 ms-0">I'm</label>
          <select
            id="choices-gender"
            class="form-control"
            name="choices-gender"
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-5 col-5">
              <label class="form-label mt-4 ms-0">Birth Date</label>
              <select
                id="choices-month"
                class="form-control"
                name="choices-month"
              ></select>
            </div>
            <div class="col-sm-4 col-3">
              <label class="form-label mt-4 ms-0">&nbsp;</label>
              <select
                id="choices-day"
                class="form-control"
                name="choices-day"
              ></select>
            </div>
            <div class="col-sm-3 col-4">
              <label class="form-label mt-4 ms-0">&nbsp;</label>
              <select
                id="choices-year"
                class="form-control"
                name="choices-year"
              ></select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="email"
            type="email"
            variant="static"
            label="Email"
            placeholder="example@email.com"
          />
        </div>
        <div class="col-6">
          <material-input
            id="phoneNumber"
            type="phone"
            variant="static"
            label="Phone Number"
            placeholder="+40 745 765 439"
          />
        </div>
        <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="locationNo"
            type="text"
            variant="static"
            label="Present Address"
            placeholder="40/532"
          /></div>
          <div class="col-6">
          <material-input
            id="locationMoo"
            type="text"
            variant="static"
            label="Moo"
            placeholder="4"
          />
        </div>
        <div class="col-6 mb-1">
          <material-input
            id="locationRoad"
            type="text"
            variant="static"
            label="Road"
            placeholder="4"
          />
        </div>
        <div class="col-6">
          <label>District</label>
          <select
            id="choices-district"
            class="form-control"
            name="choices-district"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
        </div>
        <div class="col-6">
          <label>Amphur</label>
          <select
            id="choices-amphur"
            class="form-control"
            name="choices-amphur"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
        </div>
        <div class="col-6">
          <label >Province</label>
          <select
            id="choices-province"
            class="form-control"
            name="choices-province"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
        </div>
        <div class="col-6">
          <material-input
            id="Postcode"
            type="text"
            variant="static"
            label="Postcode"
            placeholder="12122"
          />
        </div>
        </div>
        </div>
      
      <div class="row">
        <div class="col-6 ">
          <label>Role</label>
          <select
            id="choices-role"
            class="form-control"
            name="choices-role"
          >
            <option value="Director">Director</option>
            <option value="Manager">Manager</option>
            <option value="Accountant">Accountant</option>
            <option value="Admin">Admin</option>
            <option value="Staff">Staff</option>
          </select>
        </div>
        <div class="col-6 ">
          <label >Position</label>
          <select
            id="choices-position"
            class="form-control"
            name="choices-position"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div >
          <label>Level</label>
          <select
            id="choices-level"
            class="form-control"
            name="choices-level"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <material-input
          id="currentPassword"
          type="password"
          label="Current Password"
        />
      </div>
      <div class="mb-3">
        <material-input id="newPassword" type="password" label="New Password" />
      </div>
      <div class="mb-3">
        <material-input
          id="confirmPassword"
          type="password"
          label="Confirm password"
        />
      </div>
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        >Update password</material-button
      >
    </div>
  </div>
  
  <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <material-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Active</material-badge
        >
        <p class="text-secondary text-sm my-auto me-3">EU</p>
        <a
          href="javascript:;"
          class="text-success text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-success text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Safari on iPhone</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-success text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
  <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Delete Account</h5>
      <p class="text-sm mb-0">
        Once you delete your account, there is no going back. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <material-switch id="deleteAccount" name="Delete Account" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Confirm</span
          >
          <span class="text-xs d-block">I want to delete my account.</span>
        </div>
      </div>
      <material-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        >Delete Account</material-button
      >
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Dropzone from "dropzone"; // Make sure Dropzone is properly imported

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialSwitch,
    MaterialInput,
  },
  data() {
    return {
      imageUrl: '',
    };
  },
  mounted() {
    const initializeChoices = (id) => {
      const element = document.getElementById(id);
      if (element) {
        new Choices(element, { allowHTML: true });
      }
    };


    initializeChoices("choices-amphur");
    initializeChoices("choices-province");
    initializeChoices("choices-district");
    initializeChoices("choices-gender");
    initializeChoices("choices-role");
    initializeChoices("choices-position");
    initializeChoices("choices-level");

    const initializeDateChoices = (id, start, end, defaultValue) => {
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          new Choices(element, { allowHTML: true });
        }, 1);

        for (let i = start; i <= end; i++) {
          const option = document.createElement("OPTION");
          option.text = i;
          option.value = i;
          if (i === defaultValue) {
            option.selected = true;
          }
          element.options.add(option);
        }
      }
    };

    const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthElement = document.getElementById("choices-month");
    if (monthElement) {
      setTimeout(() => {
        new Choices(monthElement, { allowHTML: true });
      }, 1);

      monthArray.forEach((month, index) => {
        const option = document.createElement("OPTION");
        option.text = month;
        option.value = index + 1;
        if (index === 1) {
          option.selected = true;
        }
        monthElement.options.add(option);
      });
    }

    initializeDateChoices("choices-day", 1, 31, 1);
    initializeDateChoices("choices-year", 1900, new Date().getFullYear(), new Date().getFullYear());

    // Initialize Dropzone
    const myDropzone = new Dropzone("#productImg");
    myDropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
    }
  },
};
</script>

<style scoped>
/* Scoped styles for the component */
.flex-container {
  display: flex;
  justify-content: flex-end;
}

.dropzone {
  border: 2px dashed #007bff;
  border-radius: 0.5rem;
  padding: 20px;
  background-color: #f9f9f9;
}

.card {
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>