<template>
    <div class="py-4 container-fluid">
        <div class="col-lg-6">
          <h4>Edit Material</h4>
        </div>
      <div class="mt-4 row">
        <div class="col-lg-4">
          <div class="card mt-4" data-animation="true">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <a class="d-block blur-shadow-image">
                <img
                  :src="imageUrl"
                  alt="img-blur-shadow"
                  class="img-fluid shadow border-radius-lg"
                />
              </a>
              <div class="colored-shadow" :style="{ backgroundImage: `url(${imageUrl})` }"></div>
            </div>
            <div class="card-body text-center">
              <div class="mt-n6 mx-auto">
                <input
                  type="file"
                  ref="fileInput"
                  @change="onFileChange"
                  accept="image/*"
                  style="display: none;"
                />
                <button
                  class="btn bg-gradient-success btn-sm mb-0 me-2"
                  type="button"
                  @click="triggerFileInput"
                  name="button"
                >
                  Upload Image
                </button>
                <button
                  class="btn btn-outline-dark btn-sm mb-0"
                  type="button"
                  name="button"
                >
                  Remove
                </button>
                <h5 class="font-weight-normal mt-4">Name Product</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 col-lg-8 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Material Information</h5>
              <div class="row mt-4">
                <div class="col-12 col-sm-12">
                  <material-input id="name" variant="dynamic" label="Name" />
                </div>
                <div class="row">
                <div class="col-sm-6">
                <label class="mt-0 ms-0">Type</label>
                  <select
                    id="choices-type-edit"
                    class="form-control"
                    name="choices-type"
                  >
                    <option value="Choice 1" selected>Paper</option>
                    <option value="Choice 2">Pen</option>
                    <option value="Choice 3">Other</option>
                    <option value="Choice 4">Other</option>
                    <option value="Choice 5">Others</option>
                  </select>
                </div>
              <div class="col-6 col-sm-6 mt-4">
                  <material-input id="Order date" variant="dynamic" label="Order Date" />
                </div>
              </div>
            
              <div class="row mt-4">
                  <material-input id="price" variant="dynamic" label="Price" />
                </div>
                <div class="row">
                  <div class="col-6 col-sm-6 mt-4">
                  <material-input id="Amount" variant="dynamic" label="Amount" />
                </div>
                <div class="col-sm-6">
                <label class="mt-0 ms-0">Unit</label>
                  <select
                    id="choices-Unit"
                    class="form-control"
                    name="choices-Unit"
                  >
                  <option value="Choice 1" selected>ชิ้น</option>
                  <option value="Choice 1" selected>ด้าม</option>
                  </select>
                </div>
              
              </div>
              </div>
                <div class="col-sm-12">
                  <label class="mt-4 ms-0">Description</label>
                  <material-textarea
                    id="information"
                    variant="dynamic"
                    placeholder="Say a few words about who you are or what you're working on."
                  />
                </div>
                <div class="col-sm-12">
                  <label class="mt-2 ms-0">Order By</label>
                    <select
                    id="choices-order-edit"
                    class="form-control"
                    name="choices-order"
                  >
                    <option value="Choice 1" selected>Butter</option>
                    
                  </select>
                 <label class="mt-2 ms-0">Shop</label>
                  <select
                    id="choices-shop-edit"
                    class="form-control"
                    name="choices-shop"
                  >
                    <option value="Choice 1" selected>Black Shop </option>
                    <option value="Choice 2">White Shop</option>
                    <option value="Choice 3">Blue Shop</option>
                    <option value="Choice 4">Orange Shop</option>
                    <option value="Choice 5">Green Shop</option>
                  </select>
                </div>
              </div>
            </div>
        </div>
    </div>
        <div class="col-lg-5 d-flex justify-content-end align-items-center">
          <button
            type="button"
            class="mt-2 mb-0 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0"
          >
            Save
          </button>
        </div>
      
    </div>
  
  </template>
  
  <script>
  /* eslint-disable prettier/prettier */
  import Quill from "quill";
  import Choices from "choices.js";
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialTextarea from "@/components/MaterialTextarea.vue";
  
  export default {
    name: "EditMat",
    components: { MaterialInput, MaterialTextarea },
    data() {
      return {
        imageUrl: require("@/assets/img/products/product-11.jpg"),
      };
    },
    mounted() {
      if (document.getElementById("edit-description-edit")) {
        new Quill("#edit-description-edit", {
          theme: "snow",
        });
      }
        this.getChoices("choices-type-edit");
        this.getChoices("choices-color-edit");
        this.getChoices("choices-currency-edit");
        this.getChoices("choices-category-edit");
        this.getChoices("choices-shop-edit");
        this.getChoices("choices-order-edit");
        this.getChoices("choices-Unit");
  
      if (document.getElementById("choices-tags-edit")) {
        var tags = document.getElementById("choices-tags-edit");
        const examples = new Choices(tags, {
          removeItemButton: true,
          allowHTML: true,
        });
  
        examples.setChoices(
          [
            {
              value: "One",
              label: "Expired",
              disabled: true,
            },
            {
              value: "Two",
              label: "Out of Stock",
              selected: true,
            },
          ],
          "value",
          "label",
          false
        );
      }
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      getChoices(id) {
        if (document.getElementById(id)) {
          var element = document.getElementById(id);
          return new Choices(element, {
            searchEnabled: false,
            allowHTML: true,
          });
        }
      },
    },
  };
  </script>
  
  <style>
  .text-right {
    text-align: right;
  }
  .card {
    margin-bottom: 20px;
  }
  </style>
  