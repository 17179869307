<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3 class="mb-4">Shop</h3>
            <div class="row">
              <div class="col-xl-5 col-lg-6 text-center">
                <img
                  class="w-100 border-radius-lg shadow-lg mx-auto"
                  src="/src/assets/img/products/product-details-1.jpg"
                  alt="chair"
                />
                <div
                  id="product-gallery"
                  class="pt-2 mt-4 my-gallery d-flex gap-4"
                >
                  <a
                    v-for="(image, key) in images"
                    :key="key"
                    :href="image.largeURL"
                    :data-pswp-width="image.width"
                    :data-pswp-height="image.height"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      :src="image.thumbnailURL"
                      alt=""
                      class="shadow w-100 min-height-100 max-height-100 border-radius-lg"
                    />
                  </a>
                </div>
              </div>
              <div class="mx-auto col-lg-5">
                <h4 class="mt-4 mt-lg-0">Minimal Bar Stool</h4>
                
                <h6 class="mt-3 mb-0">Price</h6>
                <h5>$1,419</h5>
                <span class="badge badge-success">In Stock</span>
                <br />
                <label class="mt-4">Information</label>
                <ul>
                  <li><strong>Shop Name : </strong> Pemisa </li>
                  <li><strong>Tax ID Number : </strong> 12345687 </li>
                  <li><strong>Description : </strong> Say a few words about who you are or what you're working on. </li>
                  <li><strong>Phone Number : </strong> 089-9999999 </li>
                  <li><strong>Present Address : </strong> 17/4 Moo.5, Bamroongrat Road, Tambon Pibulsongkram, Amphur Muang, Bangkok, 10400 </li>
                  <li><strong>Email : </strong> xxxxx@gmail.com </li>
                </ul>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">สินค้าในคลัง</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Picture</th>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>Sum</th>
                  <th>Type</th>
                  <th>Other</th>
                 
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">Nike v22 Running</td>
                  <td class="text-sm font-weight-normal">103.00</td>
                  <td class="text-sm font-weight-normal">23/4/66</td>
                  <td class="text-sm font-weight-normal">13</td>
                  <td class="text-sm font-weight-normal">Material</td>
                  <td class="text-sm font-weight-normal">
                  <a href="/adminpage/materialdetail">view</a>
                </td>
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">Nike v22 Running</td>
                  <td class="text-sm font-weight-normal">103.00</td>
                  <td class="text-sm font-weight-normal">23/4/66</td>
                  <td class="text-sm font-weight-normal">13</td>
                  <td class="text-sm font-weight-normal">Equipment</td>
                  <td class="text-sm font-weight-normal">
                  <a href="/adminpage/equipmentdetail">view</a>
                </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
   
</template>

<script>
/* eslint-disable prettier/prettier */
import Quill from "quill";
import Choices from "choices.js";
import { DataTable } from "simple-datatables";
import productDetails1 from "/src/assets/img/products/product-details-1.jpg";
import productDetails2 from "/src/assets/img/products/product-details-2.jpg";
import productDetails3 from "/src/assets/img/products/product-details-3.jpg";
import productDetails4 from "/src/assets/img/products/product-details-4.jpg";
import productDetails5 from "/src/assets/img/products/product-details-5.jpg";
import MaterialAvatar from "../components/MaterialAvatar.vue";
import marie from "/src/assets/img/marie.jpg";

export default {
  name: "EditMat",
  components: { MaterialAvatar },
  data() {
    return {
      productDetails1,
      productDetails2,
      productDetails3,
      productDetails4,
      productDetails5,
      marie,
      images: [
        {
          largeURL: productDetails2,
          thumbnailURL: productDetails2,
          width: 500,
          height: 600,
        },
        {
          largeURL: productDetails3,
          thumbnailURL: productDetails3,
          width: 500,
          height: 600,
        },
        {
          largeURL: productDetails4,
          thumbnailURL: productDetails4,
          width: 500,
          height: 600,
        },
        {
          largeURL: productDetails5,
          thumbnailURL: productDetails5,
          width: 500,
          height: 600,
        },
      ],
      imageUrl: require("@/assets/img/products/product-11.jpg"),
    };
  },
  mounted() {

// eslint-disable-next-line no-unused-vars
const dataTableSearch = new DataTable("#datatable-search0", {
  searchable: true,
  fixedHeight: true,
});
    if (document.getElementById("edit-description-edit")) {
      new Quill("#edit-description-edit", {
        theme: "snow",
      });
    }
    this.getChoices("choices-category-edit");
    this.getChoices("choices-color-edit");
    this.getChoices("choices-currency-edit");
    this.getChoices("choices-amphur");
    this.getChoices("choices-province");
    this.getChoices("choices-district");
  
    if (document.getElementById("choices-tags-edit")) {
      var tags = document.getElementById("choices-tags-edit");
      const examples = new Choices(tags, {
        removeItemButton: true,
        allowHTML: true,
      });

      examples.setChoices(
        [
          {
            value: "One",
            label: "Expired",
            disabled: true,
          },
          {
            value: "Two",
            label: "Out of Stock",
            selected: true,
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
    },
  },
};
</script>

<style>
.text-right {
  text-align: right;
}
.card {
  margin-bottom: 20px;
}
</style>
