<template>
       <h3>Material</h3>
       <section>
      <div class="row">
        <div class="col-md-3" v-for="(card, index) in material" :key="index">
            <router-link
          :to="{ path: '/adminpage/materialdetail', query: { id: card.id } }"
          class="text-decoration-none"
        >
            <div class="card">
              <div class="card-body">
                <div class="d-flex mt-n2">
                  <div class="p-2 avatar avatar-xl bg-gradient-dark border-radius-xl mt-n4">
                    <img :src="card.image" :alt="card.name_th" />
                  </div>

                </div>
                <p class="mt-3 text-sm text-dark">{{ card.description }}</p>
                <hr class="horizontal dark" />
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 text-sm text-secondary font-weight-bold">{{ card.name_en }}</p>
                  </div>
                  <div class="col-6 text-end">
                    <h6 class="mb-0 text-sm text-dark">{{ card.dateTime }}</h6>
                    <p class="mb-0 text-sm text-secondary font-weight-normal">Start date</p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </template>
  <script>
import axios from 'axios';

  export default {
    mounted(){
    this.materiallist()
  },
    name: "ComplexProjectCard",
    data() {
      return {
        material:[],
        showMenu: false,
      };
    },
    methods:{
  async materiallist(){
    try {
      const response = await axios.get("https://uat-api.susenginetech.com/api/v1/material/list")
      console.log(response);
      if(response.data.isError){
        alert("error")
      }else{
        this.material=response.data.data
      }
    } catch (error) {
      console.log(error);
    }
  }
 }
    

  };
  </script>
  
  
  