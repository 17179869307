<template>
    <h2>Document</h2>

        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการใบเสนอราคา</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>หมายเลขQT</th>
                  <th>รายการโปรเจ็ค</th>
                  <th>โดย</th>
                  <th>สถานะ</th>
                  <th>มูลค่า</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">เสร็จสิ้น</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">รอดำเนินการ</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">ไม่อนุมัติ</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการใบวางบิล/ใบแจ้งหนี้</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>หมายเลขNote Billing</th>
                  <th>รายการ</th>
                  <th>โดย</th>
                  <th>มูลค่า</th>
                  <th>สถานะ</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">เสร็จสิ้น</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">รอดำเนินการ</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">ไม่อนุมัติ</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการใบเสร็จ/ใบกำกับภาษี</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>หมายเลขBilling</th>
                  <th>รายการ</th>
                  <th>โดย</th>
                  <th>มูลค่า</th>
                  <th>สถานะ</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">เสร็จสิ้น</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
                
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">รอดำเนินการ</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                 
                </tr>
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">23456</td>
                  <td class="text-sm font-weight-normal">Topic</td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">ไม่อนุมัติ</td>
                  <td class="text-sm font-weight-normal">20000</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button></td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
  </template>
  
  <script>

  import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { DataTable } from "simple-datatables";


  export default {
    name: "ProfileOverview",
    components: { 
    
   
      
    },
    data() {
      return {
        showMenu: false,
       
        simplepic,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
    
  
      };
    },
  
    mounted() {
  
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch1 = new DataTable("#datatable-search1", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch2 = new DataTable("#datatable-search2", {
      searchable: true,
      fixedHeight: true,
    });

    },
  };
  </script> 
  <style scoped>
  .img {
   max-width: 100%;
   height: auto;
  }

  </style>
  