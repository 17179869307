<template>
    <div class="container-fluid">
      <div class="page-header border-radius-xl mt-7"></div>
      <div class="card card-body mx-3 mx-md-4 mt-n6">
        <div class="row gx-4">
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">Create Project</h5>
            </div>
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-lg-4">
            <div class="card mt-4" data-animation="true">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <a class="d-block blur-shadow-image">
                  <img :src="imageUrl" alt="img-blur-shadow" class="img-fluid shadow border-radius-lg" />
                </a>
                <div class="colored-shadow" :style="{ backgroundImage: `url(${imageUrl})` }"></div>
              </div>
              <div class="card-body text-center">
                <div class="mt-n6 mx-auto">
                  <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none;" />
                  <button class="btn bg-gradient-success btn-sm mb-0 me-2" type="button" @click="triggerFileInput" name="button">
                    Upload Image
                  </button>
                  <button class="btn btn-outline-dark btn-sm mb-0" type="button" name="button">
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="ms-5 col-lg-11 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h6 class="font-weight-bolder">Document</h6>
              <div class="col-lg-3 col-sm-2 mt-sm-0 ">
            <default-info-card
              icon="examples/Icon/Document.vue"
              title="Document1"
              description="Belong Interactive"
            />
          </div>
            </div>
          </div>
    </div>
    <div class="container mt-2">
  <div class="row">
    <div class="col-12 col-xl-12 mb-2">
      <div class="card card-plain h-100">
        <div class="card-header p-4 pb-0">
          <h5 class="mb-3">Project Information</h5>
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-12 mb-3">
              <material-input
                id="projectName"
                variant="static"
                label="Project Name"
                placeholder="Alec"
                class="form-control"
              />
            </div>
            <div class="col-6">
          <label>Type</label>
          <select
            id="choices-district"
            class="form-control"
            name="choices-district"
          >
            <option value="choich1">Job</option>
            <option value="choich2">Company</option>
          </select>
        </div>
                <div class="col-sm-12">
                  <label class="mt-4 ms-0">Description</label>
                  <material-textarea
                    id="information"
                    variant="dynamic"
                    placeholder="Say a few words about who you are or what you're working on."
                  />
                </div>
                <div class="col-12 mb-3">
              <material-input
                id="stardatetwork"
                variant="static"
                label="Start Work"
                placeholder="12/3/67"
                class="form-control"
              />
            </div>
            <div class="col-12 mb-3">
              <material-input
                id="Enddatetwork"
                variant="static"
                label="End Work"
                placeholder="12/3/67"
                class="form-control"
              />
            </div>
            <div class="col-12 mb-3">
              <material-input
                id="overprice"
                variant="static"
                label="price"
                placeholder="1200k"
                class="form-control"
              />
            </div>
            <div class="col-6">
        <material-input
          id="location"
          type="text"
          variant="static"
          label="Location"
          placeholder="Google map"
        /></div>
          </div>
        </div>
      </div>
    </div>
      </div>
        </div>
      </div>
    <div class="mt-2 mb-2 row">
      <div class="ms-5 col-11">
        <directortist
          title="Director List"
          :headers="['Name', 'Role' ,'Position', 'Invite']"
          :lists="directors"
        />
      </div>
    </div>
    <div class="mt-2 mb-2 row">
      <div class="ms-5 col-11">
        <managerlist
          title="Manager List"
          :headers="['Name', 'Role' ,'Position', 'Invite']"
          :lists="managers"
        />
      </div>
    </div>
    <div class="mt-2 mb-2 row">
      <div class="ms-5 col-11">
        <accountlist
          title="Accountant List"
          :headers="['Name', 'Role' ,'Position', 'Invite']"
          :lists="accountants"
        />
      </div>
    </div>
    <div class="mt-2 mb-2 row">
      <div class="ms-5 col-11">
        <stafflist
          title="Staff List"
          :headers="['Name', 'Role' ,'Position', 'Invite']"
          :lists="staffs"
        />
      </div>
    </div>
    <div class="row">
    <material-button class="col-11 ms-5 btn btn-info">Create</material-button>
  </div>
  </template>
  
  <script>
  import MaterialButton from "/src/components/MaterialButton.vue";
  import sophie from "@/assets/img/kal-visuals-square.jpg";
  import marie from "@/assets/img/marie.jpg";
  import ivana from "@/assets/img/ivana-square.jpg";
  import peterson from "@/assets/img/team-4.jpg";
  import nick from "@/assets/img/team-3.jpg";
  import img1 from "@/assets/img/home-decor-1.jpg";
  import img2 from "@/assets/img/home-decor-2.jpg";
  import img3 from "@/assets/img/home-decor-3.jpg";
  import team1 from "/src/assets/img/team-1.jpg";
  import team2 from "/src/assets/img/team-2.jpg";
  import team3 from "/src/assets//img/team-3.jpg";
  import team4 from "/src/assets/img/team-4.jpg";
  import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
  import Timeline from "/src/views/pages/projects/Timeline.vue";
  import DefaultInfoCard from "/src/examples/Cards/DefaultInfoCard.vue";
  import MaterialInput from "/src/components/MaterialInput.vue";
  import MaterialTextarea from "/src/components/MaterialTextarea.vue";
  import managerlist from "../data/managerlist.vue";
  import directortist from "../data/directortist.vue";
  import accountlist from "../data/accountlist.vue";
  import stafflist from "../data/stafflist.vue";
  export default {
    name: "ProfileOverview",
    components: {
      MaterialButton,
      DefaultInfoCard,
      MaterialInput,
      MaterialTextarea,
      directortist,
      managerlist,
      accountlist,
      stafflist,
    },
    data() {
      return {
        showMenu: false,
        sophie,
        marie,
        ivana,
        peterson,
        nick,
        img1,
        team1,
        team2,
        team3,
        team4,
        img2,
        img3,
        Timeline,
        simplepic,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
        directors: [
        {
          title: "Herrod Chandler",
          
          values: ["Director","Sales Assistant"],
          info: "Refund rate is lower with 97% than other products",
          image: marie,
          
        },
        {
          title: "Brielle Williamson",
     
          values: ["Director","Integration Specialist"],
          image: nick,
        },
        {
          title: "Airi Satou",
          
          values: ["Director","Accountant"],
          image: ivana,
          
        },
        {
          title: "Ashton Cox",
          
          values: ["Director","Junior Technical Author"],
          image: peterson,
          
        },
        {
          title: "Brielle Williamson",
          
          values: ["Director","programmer"],
          info: "Refund rate is higher with 70% than other products",
          image: sophie,
          
        },
      ],
      managers: [
        {
          title: "Herrod Chandler",
          
          values: ["Manager","Sales Assistant"],
          info: "Refund rate is lower with 97% than other products",
          image: marie,
          
        },
        {
          title: "Brielle Williamson",
     
          values: ["Manager","Integration Specialist"],
          image: nick,
        },
        {
          title: "Airi Satou",
          
          values: ["Manager","Accountant"],
          image: ivana,
          
        },
        {
          title: "Ashton Cox",
          
          values: ["Manager","Junior Technical Author"],
          image: peterson,
          
        },
        {
          title: "Brielle Williamson",
          
          values: ["Manager","programmer"],
          info: "Refund rate is higher with 70% than other products",
          image: sophie,
          
        },
      ],
      accountants: [
        {
          title: "Herrod Chandler",
          
          values: ["Accountant","Sales Assistant"],
          info: "Refund rate is lower with 97% than other products",
          image: marie,
          
        },
        {
          title: "Brielle Williamson",
     
          values: ["Accountant","Integration Specialist"],
          image: nick,
        },
        {
          title: "Airi Satou",
          
          values: ["Accountant","Accountant"],
          image: ivana,
          
        },
        {
          title: "Ashton Cox",
          
          values: ["Accountant","Junior Technical Author"],
          image: peterson,
          
        },
        {
          title: "Brielle Williamson",
          
          values: ["Accountant","programmer"],
          info: "Refund rate is higher with 70% than other products",
          image: sophie,
          
        },
      ],
      staffs: [
        {
          title: "Herrod Chandler",
          
          values: ["Staff","Sales Assistant"],
          info: "Refund rate is lower with 97% than other products",
          image: marie,
          
        },
        {
          title: "Brielle Williamson",
     
          values: ["Staff","Integration Specialist"],
          image: nick,
        },
        {
          title: "Airi Satou",
          
          values: ["Staff","Accountant"],
          image: ivana,
          
        },
        {
          title: "Ashton Cox",
          
          values: ["Staff","Junior Technical Author"],
          image: peterson,
          
        },
        {
          title: "Brielle Williamson",
          
          values: ["Staff","programmer"],
          info: "Refund rate is higher with 70% than other products",
          image: sophie,
          
        },
      ],
      };
    },
 
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
    },
  };
  </script>
  
  <style scoped>
  .img {
    max-width: 100%;
    height: auto;
  }
  .information {
    font-size: 14px;
    color: #555454;
    margin: 30px 0;
    line-height: 30px;
  }
  </style>
  