<template>
    <div class="col-lg-6 mt-3">
    <h2 >Company / Freelance</h2>
</div>
    <div class="container">
      <div class="card shadow-lg p-4">
        <div class="row g-4 align-items-center">
          <div class="col-md-4 text-center">
            <div class="text-center mb-4">
              <material-avatar
                :img="marie"
                class="mb-4"
                alt="Avatar"
                size="xxl"
                border-radius="md"
              />
            </div>
            
          </div>
          <div class="col-md-8">
            <h4 class="mb-3">Information Company/Freelance</h4>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><strong>Name:</strong> Suset Co,.Ltd</li>
              <li class="list-group-item"><strong>AKA:</strong> Suset</li>
              <li class="list-group-item"><strong>เลขที่ภาษี :</strong> 40745765439</li>
              <li class="list-group-item"><strong>email:</strong> xxxx@gmail.com</li>
              <li class="list-group-item"><strong>Phone Number:</strong> 089-9999999</li>
              <li class="list-group-item"><strong>Start work:</strong> 4 / 03 / 2024</li>
              <li class="list-group-item"><strong>Address:</strong> 17/4 Moo.5, Bamroongrat Road, Tambon Pibulsongkram, Amphur Muang, Bangkok, 10400</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
            <div class="py-4 container-fluid">
        <div class="card-header">
          <div class="mt-4 row">
          <div class="col-12">
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h5 class="mb-0">ผู้ติดต่อ</h5>
              </div>
              <div class="table-responsive">
                <table id="datatable-search0" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>Title</th>
                      <th>Firstname</th>
                      <th>Lastname</th>
                      <th>Position</th>
                      <th>Phone Number</th>
                      <th>Other</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                        <td  class="text-sm font-weight-normal ">Ms.</td>
                      <td class="text-sm font-weight-normal">Pemisa</td>
                      <td class="text-sm font-weight-normal">Trisuwan</td>
                      <td class="text-sm font-weight-normal">Programmer</td>
                      <td class="text-sm font-weight-normal">086543217</td>
                      <td><material-button class="bt btn-info">Edit</material-button>
                        <button class="mb-0 ms-3 btn btn-danger"
        variant="gradient"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal">
          Delete
        </button>
            <!-- Modal -->
            <div class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirm For Delete</h5>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    Are you sure you want to delete this data?<br /><br />
                    This action cannot be undone.
                    <material-alert class="font-weight-light" color="danger" dismissible>
              <span class="text-sm"
                >A simple danger alert with
                <a href="javascript:;" class="alert-link text-white"
                  >an example link</a
                >. Give it a click if you like.</span
              ></material-alert>
                  </div>
                  <div class="modal-footer justify-content-between">
                    <button type="button"
                            class="btn btn-dark"
                            data-bs-dismiss="modal">
                      Cancle
                    </button>
                    <button type="button"
                            class="btn btn-danger "
                            data-target="dangerToast"
                            @click="snackbar = 'danger'">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div></td>                
    
                    </tr>
                  </tbody>
                </table>
                <DIV class="row ">
                  <div class="col-1">
            <material-input
              id="Title"
              variant="static"
              label="Title"
              placeholder="Ms./Mr."
            />
          </div>
          <div class="col-2">
            <material-input
              id="Firstname"
              variant="static"
              label="Firstname"
              placeholder="Alec"
            />
          </div>
                  <div class="col-2">
            <material-input
              id="Lastname"
              variant="static"
              label="Lastname"
              placeholder="Alec"
            />
          </div>
          <div class="col-2">
            <material-input
              id="Position"
              variant="static"
              label="Position"
              placeholder="Programmer"
            />
          </div>
          <div class="col-2">
            <material-input
              id="Phone Number"
              variant="static"
              label="Phone Number"
              placeholder="086543210"
            />
          </div>
                  <material-button class="btn bg-gradient-sucess col-2 toast-btn"
                  type="button"
                  data-target="dangerToast"
                  @click="snackbar = 'success'">Add</material-button>
              </DIV>

              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="position-fixed bottom-1 end-1 z-index-2">
      <material-snackbar
        v-if="snackbar === 'success'"
        title="Material Dashboard"
        date="11 mins ago"
        description="Hello, world! This is a notification message."
        :icon="{ component: 'done', color: 'white' }"
        color="success"
        :close-handler="closeSnackbar"
      />
            </div>
            </div>
      </template>
      
      <script>
    
      import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
      import setNavPills from "@/assets/js/nav-pills.js";
      import setTooltip from "@/assets/js/tooltip.js";
      import sophie from "@/assets/img/kal-visuals-square.jpg";
      import { DataTable } from "simple-datatables";
      import marie from "@/assets/img/marie.jpg";
      import MaterialAvatar from "/src/components/MaterialAvatar.vue";
      import MaterialButton from "/src/components/MaterialButton.vue";
      import MaterialInput from "/src/components/MaterialInput.vue";
      import MaterialAlert from "@/components/MaterialAlert.vue";
      import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

      export default {
        name: "ProfileOverview",
        components: {MaterialAvatar,MaterialButton,MaterialInput,MaterialAlert,MaterialSnackbar
        
       
          
        },
        data() {
          return {
            showMenu: false,
            snackbar: null,
            sophie,
            marie,
            simplepic,
            imageUrl: require("@/assets/img/products/product-11.jpg"),
        
      
          };
        },
        methods: {
        closeSnackbar() {
        this.snackbar = null;
    },
  },
        mounted() {
      
          setNavPills();
          setTooltip(this.$store.state.bootstrap);
          // eslint-disable-next-line no-unused-vars
        const dataTableSearch = new DataTable("#datatable-search0", {
          searchable: true,
          fixedHeight: true,
        });
        },
        
      };
      </script> 
      <style scoped>
      .img {
       max-width: 100%;
       height: auto;
      }
    
      </style>
      