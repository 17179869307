import { createRouter, createWebHistory } from "vue-router";
//adminpage//
import dashboard from "/src/adminpage/dashboard.vue";
import profilepage from "@/adminpage/profilepage.vue";
import editmember from "../adminpage/editmember.vue";
import addmaterial from "/src/adminpage/addmater.vue";
import material from "../adminpage/material.vue";
import addcoworker from "@/adminpage/addcoworker.vue";
import coworker from "../adminpage/coworkerpage.vue";
import addEquipment from "/src/adminpage/addequip.vue";
import registershop from "../adminpage/registershop.vue";
import projectdetail from "../adminpage/projectdetail.vue";
import shopdetail from "../adminpage/shopdetail.vue";
import shoppage from "../adminpage/shoppage.vue";
import materialdetail from "../adminpage/materialdetail.vue";
import equipmentdetail from "../adminpage/equipmentdetail.vue";
import project from "../adminpage/project.vue";
import createproject from "../adminpage/createproject.vue";
import memberpage from "../adminpage/memberpage.vue";
import eqiupment from "/src/adminpage/eqiupment.vue";
import editdetailproject from "../adminpage/editdetailproject.vue";
import updatetimeline from "../adminpage/updatetimeline.vue";
import purchesproject from "../adminpage/purchesproject.vue";
import appoveproject from "/src/adminpage/appoveproject.vue";
import appovepersonal from "../adminpage/appovepersonal.vue";
import appovesalary from "/src/adminpage/appovesalary.vue";
import appoveOT from "../adminpage/appoveOT.vue";
import appoveBonus from "../adminpage/appoveBonus.vue";
import appovefinance from "../adminpage/appovefinance.vue";
import appovepurches from "../adminpage/appovepurches.vue";
import FinanceOT from "../adminpage/FinanceOT.vue";
import FinanceSalary from "../adminpage/FinanceSalary.vue";
import FinanceBonus from "../adminpage/FinanceBonus.vue";
import profilefinance from "../adminpage/profilefinance.vue";
import profliefinanceproject from "../adminpage/profliefinanceproject.vue";
import financepurches from "../adminpage/financepurches.vue";
import financereceipts from "../adminpage/financereceipts.vue";
import financeexpenses from "../adminpage/financeexpenses.vue";
import document from "../adminpage/document.vue";
import memberlist from "../adminpage/memberlist.vue";
import memberdocument from "/src/adminpage/memberdocument.vue";
import registermember from "../adminpage/registermember.vue";
import editequipment from "../adminpage/editequipment.vue";
import editmaterial from "../adminpage/editmaterial.vue";
import editshop from "../adminpage/editshop.vue";
import coworkerdetailpage from "../adminpage/coworkerdetail.vue";
import documentreceipt from "../adminpage/documentreceipt.vue";
import QObiling from "../adminpage/QObiling.vue";
import editprofile from "../adminpage/editprofile.vue";
import settingpage from "../adminpage/settingpage.vue";
import settingproject from "../adminpage/settingproject.vue";
import billingnote from "../adminpage/billingnote.vue";
import loginpage from "../adminpage/loginpage.vue";
import forgetpassword from "../adminpage/forgetpassword.vue";
import test from "../adminpage/test.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/adminpage/dashboard",
  },
  {
    path: "/forgetpassword",
    name: "forgetpassword",
    component: forgetpassword,
  },
  {
    path: "/loginpage",
    name: "loginpage",
    component: loginpage,
  },
  {
    path: "/adminpage/dashboard",
    name: "dashboard",
    component: dashboard,
  },
  {
    path: "/adminpage/profilepage",
    name: "profilepage",
    component: profilepage,
  },
  {
    path: "/adminpage/editmember",
    name: "editmember",
    component: editmember,
  },
  {
    path: "/adminpage/addmaterial",
    name: "addmaterial",
    component: addmaterial,
  },
  {
    path: "/adminpage/material",
    name: "material",
    component: material,
  },
  {
    path: "/adminpage/addcoworker",
    name: "addcoworker",
    component: addcoworker,
  },
  {
    path: "/adminpage/coworkerpage",
    name: "coworker",
    component: coworker,
  },
  {
    path: "/adminpage/coworkerdetail",
    name: "coworkerdetailpage",
    component: coworkerdetailpage,
  },
  {
    path: "/adminpage/addequipment",
    name: "addequipment",
    component: addEquipment,
  },
  {
    path: "/adminpage/registershop",
    name: "registershop",
    component: registershop,
  },
  
  {
    path: "/adminpage/shoppage",
    name: "shoppage",
    component: shoppage,
  },
  {
    path: "/adminpage/projectdetail",
    name: "projectdetail",
    component: projectdetail,
  },
  {
    path: "/adminpage/shopdetail",
    name: "shopdetail",
    component: shopdetail,
  },
  {
    path: "/adminpage/materialdetail",
    name: "materialdetail",
    component: materialdetail,
  },
  {
    path: "/adminpage/equipmentdetail",
    name: "equipmentdetail",
    component: equipmentdetail,
  },
  {
    path: "/adminpage/project",
    name: "project",
    component: project,
  },
  {
    path: "/adminpage/createproject",
    name: "createproject",
    component: createproject,
  },
  {
    path: "/adminpage/memberpage",
    name: "memberpage",
    component: memberpage,
  },
  {
    path: "/adminpage/eqiupment",
    name: "eqiupment",
    component: eqiupment,
  },
  {
    path: "/adminpage/editdetailproject",
    name: "editdetailproject",
    component: editdetailproject,
  },
  {
    path: "/adminpage/updatetimeline",
    name: "updatetimeline",
    component: updatetimeline,
  },
  {
    path: "/adminpage/purchesproject",
    name: "purchesproject",
    component: purchesproject,
  },
  {
    path: "/adminpage/appoveproject",
    name: "appoveproject",
    component: appoveproject,
  },
  {
    path: "/adminpage/appovesalary",
    name: "appovesalary",
    component: appovesalary,
  },
  {
    path: "/adminpage/appoveOT",
    name: "appoveOT",
    component: appoveOT,
  },
  {
    path: "/adminpage/appoveBonus",
    name: "appoveBonus",
    component: appoveBonus,
  },
  {
    path: "/adminpage/appovefinance",
    name: "appovefinance",
    component: appovefinance,
  },
  {
    path: "/adminpage/appovepurches",
    name: "appovepurches",
    component: appovepurches,
  },
  {
    path: "/adminpage/appovepersonal",
    name: "appovepersonal",
    component: appovepersonal,
  },
  {
    path: "/adminpage/FinanceOT",
    name: "FinanceOT",
    component: FinanceOT,
  },
  {
    path: "/adminpage/FinanceBonus",
    name: "FinanceBonus",
    component: FinanceBonus,
  },
  {
    path: "/adminpage/FinanceSalary",
    name: "FinanceSalary",
    component: FinanceSalary,
  },
  {
    path: "/adminpage/profilefinance",
    name: "profilefinance",
    component: profilefinance,
  },
  {
    path: "/adminpage/profliefinanceproject",
    name: "profliefinanceproject",
    component: profliefinanceproject,
  },
  {
    path: "/adminpage/financepurches",
    name: "financepurches",
    component: financepurches,
  },
  {
    path: "/adminpage/financereceipts",
    name: "financereceipts",
    component: financereceipts,
  },
  {
    path: "/adminpage/financeexpenses",
    name: "financeexpenses",
    component: financeexpenses,
  },
  {
    path: "/adminpage/document",
    name: "document",
    component: document,
  },
  {
    path: "/adminpage/memberlist",
    name: "memberlist",
    component: memberlist,
  },
  {
    path: "/adminpage/memberdocument",
    name: "memberdocument",
    component: memberdocument,
  },
  {
    path: "/adminpage/registermember",
    name: "registermember",
    component: registermember,
  },
  {
    path: "/adminpage/editequipment",
    name: "editequipment",
    component: editequipment,
  },
  {
    path: "/adminpage/editmaterial",
    name: "editmaterial",
    component: editmaterial,
  },
  {
    path: "/adminpage/editshop",
    name: "editshop",
    component: editshop,
  },
  {
    path: "/adminpage/documentreceipt",
    name: "documentreceipt",
    component: documentreceipt,
  },
  {
    path: "/adminpage/QObiling",
    name: "QObiling",
    component: QObiling,
  },
  {
    path: "/adminpage/editprofile",
    name: "editprofile",
    component: editprofile,
  },
  {
    path: "/adminpage/settingpage",
    name: "settingpage",
    component: settingpage,
  },
  {
    path: "/adminpage/settingproject",
    name: "settingproject",
    component: settingproject,
  },
  {
    path: "/adminpage/billingnote",
    name: "billingnote",
    component: billingnote,
  },
  
  
  {
    path: "/adminpage/test",
    name: "test",
    component: test,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
