<template>
    <div class="col-lg-6 mt-3">
      <h2>Setting Project</h2>
    </div>
<form>
    <div class="card-header mt-4 ">
    <div>
        <material-switch>Public(ออกสู่สาธารณะ)</material-switch>
    </div>
    <div class="ms-5">
        <material-switch>มูลค่างาน</material-switch>
        <material-switch>การดำเนินงาน(ไทม์ไลน์)</material-switch>
        <material-switch>ปัญหาการทำงาน(ไทม์ไลน์)</material-switch>
        <material-switch>ค่าใช้จ่าย</material-switch>
        <material-switch>ข้อมูลผู้ร่วมงาน</material-switch>
        <material-switch>ข้อมูลพนักงาน</material-switch>
        <material-switch>คนจ้างงาน</material-switch>
    </div>
    <div class="card-header mt-4 ">
    </div>
    <h5>เชิญบุคคลภายนอกเข้า</h5>
    <p>(เข้าหน้าสาธารณะมีช่องcommentเพิ่มขึ้น)</p>
    <p>(เฉพาะคนที่ได้รับลิงค์สามารถcomment ไทม์ไลน์การทำงาน)</p>
    <p>URL Link  <MaterialInput type="text" placeholder="ganerate link" disabled /></p>
    <material-switch>Can view</material-switch>
    <material-switch>Can comment</material-switch>

    </div>
</form>
</template>
<script>
  import MaterialSwitch from "@/components/MaterialSwitch.vue";
  import MaterialInput from "@/components/MaterialInput.vue";

  export default {
    components: { MaterialSwitch,MaterialInput},

  }

</script>