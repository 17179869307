<template>
  <div class="table-responsive">
  <table id="datatable-search2" class="table table-flush">
    <thead class="thead-light">
      <tr>
        <th>เงื่อนไขการชำระเงิน</th>
        <th>จำนวน</th>
        <th>รายละเอียด</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(condition, index) in paymentConditions" :key="index">
        <td></td>
        <td class="text-sm font-weight-normal">
          <input v-model="condition.mount" class="inline-input mx-2 col-4" style="width: auto;" placeholder="30%" />
        </td>
        <td class="text-sm font-weight-normal">
          <input v-model="condition.des" class="inline-input mx-2 col-4" style="width: auto;" placeholder="หลังตกลงสั่งจ้างและส่งแบบการติดตั้ง" />
        </td>
        <td class="text-sm font-weight-normal">
          <button @click="addRow1" class="btn bg-gradient-success mb-0 mt-0 mt-md-n9 mt-lg-0">
            <i class="material-icons text-white position-relative text-md pe-2">add</i>Add
          </button>
          <button @click="removeRow1(index)" class="btn bg-gradient-danger mb-0 mt-0 mt-md-n9 mt-lg-0">
            <i class="material-icons text-white position-relative text-md pe-2">delete</i>Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
      
</template>
<script>
export default {
  data() {
    return {
      paymentConditions: [
        { mount: 0, des: '' },
      ],
      totalMount: 0, // เก็บค่ารวมของ mount
    };
  },
  methods: {
    // ฟังก์ชันคำนวณเปอร์เซ็นต์รวม
    calculateTotalMount() {
      this.totalMount = this.paymentConditions.reduce((sum, condition) => sum + parseFloat(condition.mount || 0), 0);
    },
    
    // ฟังก์ชันสำหรับเพิ่มบรรทัดใหม่
    addRow1() {
      // ตรวจสอบว่าเปอร์เซ็นต์รวมไม่เกิน 100
      this.calculateTotalMount();
      if (this.totalMount < 100) {
        this.paymentConditions.push({ mount: 0, des: '' });
      } else {
        alert("ไม่สามารถเพิ่มได้ เนื่องจากเปอร์เซ็นต์รวมเกิน 100%");
      }
    },
    
    // ฟังก์ชันสำหรับลบบรรทัด
    removeRow1(index) {
      if (this.paymentConditions.length > 1) {
        this.paymentConditions.splice(index, 1);
        this.calculateTotalMount(); // อัปเดตค่ารวมหลังจากลบ
      }
    },
    
    // ฟังก์ชันสำหรับตรวจสอบค่า mount เมื่อมีการแก้ไข
    updateMount() {
      this.calculateTotalMount();
      if (this.totalMount > 100) {
        alert("เปอร์เซ็นต์รวมเกิน 100%");
      }
    }
  },
  watch: {
    // ตรวจสอบเมื่อค่า mount เปลี่ยนแปลง
    'paymentConditions': {
      handler: 'updateMount',
      deep: true
    }
  }
};
</script>


