import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import axios from 'axios'

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.mount("#app");

appInstance.config.globalProperties.$axios = axios
axios.defaults.baseURL = process.env.SS_API_URL


axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    console.log('Axios [Request] : ', config)
    return config

})

axios.interceptors.response.use((response) => {
    console.log('Axios [Response] : ', response)
    // Handle the response here
    return response
}, (error) => {
    console.log('Axios [Response * Error!] : ', error)
    if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem('authToken')
            //TODO POP up Alert Session Timeout
            alert('Session Timeout');
            // Redirect to login page
            router.push('/loginpage')
        } else {
            // Show a generic error message
            // alert('An error occurred. Please try again later.')
        }
    }
    return Promise.reject(error)
})
