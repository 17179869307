<template>
    <form>
      <div class="card header mt-3">
        <h2>Billing / ใบวางบิล</h2>
          <p class="styled-paragraph">
  
            89/216 หมู่บ้าน คาซ่าซิตี้วัชรพล -เพิ่มสิน 2 แขวงสายไหม เขตสายไหม กรุงเทพมหานคร 10220 
            <br>โทร 0-2509-2121 แฟกซ์ 0-2509-2122
            <br>89/216 Casa City Watcharapol - Permsin 2, SAIMAI, Bangkok 10220
            <br>TEL.0-2509-2121 
            <br>FAX 0-2509-2122
            <br>เลขประจำตัวผู้เสียภาษี : 0-1055-67073-40-1
      </p>
        <div class="container">
          <h5>ชื่อ Co-worker</h5>
          <p class="styled-paragraph">
            บริษัท ไทยเทรด แอนด์ บิวท์ จำกัด
            <br>คุณ สัมพันธ์ บัวจะมะ
            <br>เลขที่ 3/227 ถนนเทศบาลนิมิตรเหนือ แขวงลาดยาว เขตจตุจักร กทม
        </p>
    
          <p>วันที่ออกใบ</p>
            <div class="mb-3 col-4">
              <material-input type="date" variant="dynamic"  />
        </div>
        <!-- <p>บริษัทมีความยินดีขอเสนอราคาสินค้า / บริการ ดังมีรายละเอียดดังนี้</p> -->
        <div class="table-responsive">
              <table id="datatable-search2" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>ลำดับ</th>
                    <th>รายการ</th>
                    <th>ราคา</th>
                    <th>จำนวน</th>
                    <th>หน่วย</th>
                    <th>ราคารวม</th>
                  </tr>
                </thead>
                <tbody>
                  
                  <tr class="text-sm font-weight-normal">
                    <td><input id="num" v-model="rows.num" variant="dynamic" label="ลำดับ" /></td>
                    <td>
                      <select id="choices-month" class="form-control" name="choices-month">
                        <option value="choice1">30% เมื่อตกลงสั่งจ้างและส่งแบบงานติดตั้ง</option>
                        <option value="choice2">2</option>
                      </select></td>
                    <td><input v-model.number="form.qo_quantity" v-model="quantity" type="number" class="form-control" name="qo_quantity" id="qo_quantity" placeholder="จำนวน"></td>
                    <td><input v-model.number="form.qo_price" v-model="price" type="number" step="any" class="form-control" name="qo_price" id="qo_price" placeholder="ราคา"></td>
                    <td><input id="unit" v-model="unit" variant="dynamic" label="หน่วย" /></td>
                    <td><input :value="total" type="number" step="any" class="form-control" name="qo_total" id="qo_total" placeholder="ราคารวม" disabled></td>
                                   
                  <td>
                      <material-button 
                      class="btn bg-gradient-success mb-0 mt-0 mt-md-n9 mt-lg-0"  @click="addRow">
                      <i class="material-icons text-white position-relative text-md pe-2"
                      >add</i>Add </material-button></td>
                      <td>
                      <material-button 
                      class="btn bg-gradient-success mb-0 mt-0 mt-md-n9 mt-lg-0"  @click="removeRow">
                      <i class="material-icons text-white position-relative text-md pe-2"
                      >delete</i>Remove </material-button></td>
                              
                    </tr>
                     <tr class="text-sm font-weight-normal">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>รวมมูลค่าสินค้าหรือบริการ</td>
                    <td><input :value="total" type="number" step="any" class="form-control" name="qo_total" id="qo_total" placeholder="ราคารวม" disabled></td>
                  </tr>
                  <tr class="text-sm font-weight-normal">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>ภาษีมูลค่าเพิ่ม/V.A.T 7%</td>
                    <td><input :value="total_VAT" type="number" step="any" class="form-control" name="qo_total_VAT" id="qo_total_VAT" placeholder="VAT" disabled>
                    </td>
                  </tr>
                  <tr class="text-sm font-weight-normal">
                    <td></td>
                    <td><MaterialInput id="th" variant="dynamic" label="แปลงเป็นตัวอักษรไทย" /></td>
                    <td></td>
                    <td></td>
                    <td>ราคาสุทธิ</td>
                    <td><input :value="total_sum" type="number" step="any" class="form-control" name="qo_total_sum" id="qo_total_sum" placeholder="ราคาสุทธิ" disabled>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
        <h6 class="d-flex align-items-center col-7" >หมายเหตุ :</h6>
                    <material-textarea
                      id="information"
                      variant="dynamic"
                      placeholder="Say a few words about who you are or what you're working on."
                    />
        <h6 class="d-flex align-items-center col-7" >การชำระ :</h6>
        <material-checkbox id="checkboxId">บัญชีธนาคาร</material-checkbox>
        <material-checkbox id="checkboxId">เช็กเงินสด</material-checkbox>
        <material-checkbox id="checkboxId">อื่นๆ</material-checkbox>
        <p><strong>ผู้รับเอกสารวางบิล<MaterialInput id="th" variant="dynamic"/></strong></p>
        <p>วันที่รับใบ</p>
            <div class="mb-3 col-4">
              <material-input type="date" variant="dynamic"  />
        </div>
      <p><strong>ในนามบริษัท ซัซ เอ็นจิ้น เทค จำกัด</strong></p>
      <p><strong>ผู้มีอำนาจลงนาม / AUTHORIZED SIGNATURE</strong></p>
      <div class="col-4">
      <select id="choices-members" class="form-control" name="choices-members" placeholder="ผู้มีอำนาจลงนาม / AUTHORIZED SIGNATURE">
        <option value="choice1">เปมิศา ไตรสุวรรณ์</option>
        <option value="choice2">2</option>
        <option value="choice3">3</option>
        <option value="choice4">4</option>
        <option value="choice5">5</option>
      </select>
    </div>
      </div>
        </div>
      </form>
    </template>
    
    <script>
    import * as Choices from "choices.js";
    import MaterialInput from "@/components/MaterialInput.vue";
    import MaterialButton from "/src/components/MaterialButton.vue";
    import MaterialTextarea from "@/components/MaterialTextarea.vue";
    import MaterialCheckbox from "@/components/MaterialCheckbox.vue";

    export default {
      name: "QuotationForm",
      components: {
        MaterialInput,
        MaterialButton,
        MaterialTextarea,MaterialCheckbox
      },
      data() {
        return {
          form: {
            qo_quantity: 0,
            qo_price: 0,
            qo_discount: 0,
            qo_vat: 0.07,
          },
          rows: [
        { num: "",
          list: "",
          quantity:0,
          price:0,
          unit:"",
          totals:0}
      ],
        };
      },
      methods: {
      addRow: function () {
        this.rows.push({
         num: "",
         list: "",
         quantity:0, 
         price:0, 
         unit:"",
         totals:0})
      },
      removeRow: function (row) {
        //console.log(row);
        this.rows.$remove(row)
      },
  
      computed: {
        total() {
          return this.form.qo_price * this.form.qo_quantity;
        },
        total_discount() {
          return this.total - this.form.qo_discount;
        },
        total_VAT() {
          return this.total_discount * this.form.qo_vat;
        },
        total_sum() {
          return this.total_discount + this.total_VAT;
        },
      },
      mounted() {
        const initializeChoices = (id) => {
          const element = document.getElementById(id);
          if (element) {
            new Choices(element, { allowHTML: true });
          }
        };
        initializeChoices("choices-company");
        initializeChoices("choices-member");
        initializeChoices("choices-month");
        initializeChoices("choices-year");
        initializeChoices("choices-time");
        initializeChoices("choices-members");
      },
      }
    };
    </script>
    <style scoped>
    .styled-paragraph {
        border: 1px solid #6d6d6d; /* Creates a solid border */
        padding: 20px; /* Adds space inside the border */
        margin: 20px; /* Adds space outside the border */
        background-color: #f9f9f9; /* Background color for the paragraph */
        font-family: Arial, sans-serif; /* Font styling */
        line-height: 1.6; /* Spacing between lines */
        border-radius: 8px; /* Rounded corners */
      }
    </style>
    