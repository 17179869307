<template>
   <div class="col-lg-6 mt-3">
    <h4>Register Member</h4>
  </div>
      <!-- Upload Section -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card p-3">
            <div
              id="productImg"
              action="/file-upload"
              class="form-control border dropzone"
            ></div>
           
          </div>
        </div>
      </div>
    <div id="basic-info" class="card mt-4">
      <div class="card-header">
        <h5>Basic Information</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-6">
            <material-input
              id="firstName"
              variant="static"
              label="First Name"
              placeholder="Alec"
            />
          </div>
          <div class="col-6">
            <material-input
              id="lastName"
              variant="static"
              label="Last Name"
              placeholder="Thompson"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <material-input
              id="firstNameTH"
              variant="static"
              label="ชื่อจริง"
              placeholder="เปมิศา"
            />
          </div>
          <div class="col-6">
            <material-input
              id="lastNameTH"
              variant="static"
              label="นามสกุล"
              placeholder="ไตรสุวรรณ์"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-6">
            <label class="form-label mt-4 ms-0">I'm</label>
            <select
              id="choices-gender"
              class="form-control"
              name="choices-gender"
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-5 col-5">
                <label class="form-label mt-4 ms-0">Start Date</label>
                <select
                  id="choices-month"
                  class="form-control"
                  name="choices-month"
                ></select>
              </div>
              <div class="col-sm-4 col-3">
                <label class="form-label mt-4 ms-0">&nbsp;</label>
                <select
                  id="choices-day"
                  class="form-control"
                  name="choices-day"
                ></select>
              </div>
              <div class="col-sm-3 col-4">
                <label class="form-label mt-4 ms-0">&nbsp;</label>
                <select
                  id="choices-year"
                  class="form-control"
                  name="choices-year"
                ></select>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <material-input
              id="email"
              type="email"
              variant="static"
              label="Email"
              placeholder="example@email.com"
            />
          </div>
          <div class="col-6">
            <material-input
              id="phoneNumber"
              type="phone"
              variant="static"
              label="Phone Number"
              placeholder="+40 745 765 439"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <material-input
              id="locationNo"
              type="text"
              variant="static"
              label="Present Address"
              placeholder="40/532"
            /></div>
          
          <div class="col-6">
            <label>District</label>
            <select
              id="choices-district"
              class="form-control"
              name="choices-district"
            >
              <option value="choich1">1</option>
              <option value="choich2">2</option>
              <option value="choich3">3</option>
              <option value="choich4">4</option>
              <option value="choich5">5</option>
            </select>
          </div>
          <div class="col-6">
            <label>Amphur</label>
            <select
              id="choices-amphur"
              class="form-control"
              name="choices-amphur"
            >
              <option value="choich1">1</option>
              <option value="choich2">2</option>
              <option value="choich3">3</option>
              <option value="choich4">4</option>
              <option value="choich5">5</option>
            </select>
          </div>
          <div class="col-6">
            <label >Province</label>
            <select
              id="choices-province"
              class="form-control"
              name="choices-province"
            >
              <option value="choich1">1</option>
              <option value="choich2">2</option>
              <option value="choich3">3</option>
              <option value="choich4">4</option>
              <option value="choich5">5</option>
            </select>
          </div>
          <div class="col-6">
            <material-input
              id="Postcode"
              type="text"
              variant="static"
              label="Postcode"
              placeholder="12122"
            />
          </div>
          <div class="col-6">
            <material-input
              id="Location"
              type="text"
              variant="static"
              label="Location"
              placeholder="map"
            /></div>
       
            <material-input
              id="Salary"
              variant="static"
              label="Salary"
              placeholder="20,000"
            />
          </div>
          </div>
          </div>
    <div id="user" class="card mt-4">
    <div class="card-header">
      <h5>Setting User</h5>
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <material-input id="newUser" type="User" label="New User" />
      </div>
      <div class="mb-3">
        <material-input id="newPassword" type="password" label="New Password" />
      </div>
      <div class="mb-3">
        <material-input
          id="confirmPassword"
          type="password"
          label="Confirm password"
        />
      </div>
      <h6 class="mt-5">Password requirements</h6>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
    </div>
    
      <material-button
          color="success"
          variant="gradient"
          class="mb-3 ms-auto"
          type="button"
          name="button"
        >Submit</material-button>
    </div>

  </template>
  
  <script>
  import * as Choices from "choices.js";
  import MaterialButton from "@/components/MaterialButton.vue";
  import MaterialInput from "@/components/MaterialInput.vue";
  import Dropzone from "dropzone"; // Make sure Dropzone is properly imported
  
  export default {
    name: "SideNavItem",
    components: {
      MaterialButton,

      MaterialInput,
    },
    data() {
      return {
        imageUrl: '',
      };
    },
    mounted() {
      const initializeChoices = (id) => {
        const element = document.getElementById(id);
        if (element) {
          new Choices(element, { allowHTML: true });
        }
      };
  
      initializeChoices("choices-amphur");
      initializeChoices("choices-province");
      initializeChoices("choices-district");
      initializeChoices("choices-amphur1");
      initializeChoices("choices-province1");
      initializeChoices("choices-district1");
      initializeChoices("choices-gender");
      initializeChoices("choices-role");
      initializeChoices("choices-position");
      initializeChoices("choices-level");
  
      const initializeDateChoices = (id, start, end, defaultValue) => {
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => {
            new Choices(element, { allowHTML: true });
          }, 1);
  
          for (let i = start; i <= end; i++) {
            const option = document.createElement("OPTION");
            option.text = i;
            option.value = i;
            if (i === defaultValue) {
              option.selected = true;
            }
            element.options.add(option);
          }
        }
      };
  
      const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const monthElement = document.getElementById("choices-month");
      if (monthElement) {
        setTimeout(() => {
          new Choices(monthElement, { allowHTML: true });
        }, 1);
  
        monthArray.forEach((month, index) => {
          const option = document.createElement("OPTION");
          option.text = month;
          option.value = index + 1;
          if (index === 1) {
            option.selected = true;
          }
          monthElement.options.add(option);
        });
      }
  
      initializeDateChoices("choices-day", 1, 31, 1);
      initializeDateChoices("choices-year", 1900, new Date().getFullYear(), new Date().getFullYear());
  
      // Initialize Dropzone
      const myDropzone = new Dropzone("#productImg");
      myDropzone.on("addedfile", (file) => {
        console.log(`File added: ${file.name}`);
      });
    },
    methods: {
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      onFileChange(event) {
        const file = event.target.files[0];
        this.imageUrl = URL.createObjectURL(file);
      }
    },
  };
  </script>
  
  <style scoped>
  /* Scoped styles for the component */
  .flex-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .dropzone {
    border: 2px dashed #007bff;
    border-radius: 0.5rem;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .card {
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  </style>
  