<template>
  <h2>Profile Finance Project</h2>
  <div class="container-fluid">
    <div class="card">
        <div class="row">
          <div class=" row">
            <div class="col-6 position-relative">
              <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <material-avatar
                    :img="marie"
                    class="mb-4"
                    alt="Avatar" size="xxl" border-radius="md"
                  />
              </div>
            </div>
            <h6>ข้อมูลโปรเจ็ค</h6>
            <ul class="information">
              <li><strong>Type : </strong>Job/company</li>
                  <li><strong> Information : </strong> Hi, Im Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)</li>
                  <li><strong>Start Date : </strong> 20 / 03 / 2024 </li>
                  <li><strong>Due Date : </strong> 20 / 03 / 2024 </li>
                  <li><strong>price : </strong> 1234567 </li>
                  <li><strong>Status : </strong> doing</li>
                  <li><strong>Address : </strong> 17/4 Moo.5, Bamroongrat Road, Tambon Pibulsongkram, Amphur Muang, Bangkok, 10400 </li>
                  <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
        <default-statistics-card
          class="col col-lg-12  "
          title="เงินที่โปรเจ็ค"
          count="2,000,000.00 บาท"
          :percentage="{ color: 'success'}"
          
        />
      </li>
      <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
        <default-statistics-card
          class="col col-lg-12  "
          title="กำไร/ขาดทุน"
          count="2,000.00 บาท"
          :percentage="{
          color: 'success',
          value: '+55%',
          label: 'since last month'}"
         
        />
      </li>
            </ul>
            
           
          </div>
          <div class="mt-4 col-8 col-xl-4 mt-xl-0">
    <div class="card card-plain h-100">
      <div class="p-3 pb-0 card-header">
        <h6 class="mb-0">เงินเดือนปัจจุบัน</h6>
      </div>
      <div class="p-3 card-body">
        <ul class="list-group">
          <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
        
          <default-statistics-card
            class="col col-lg-12  "
            title="มูลค่ารายรับ"
            count="100,000.00 บาท"
            :percentage="{ color: 'success'}"
           
          />
        </li>
        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
        
        <default-statistics-card
          class="col col-lg-12  "
          title="มูลค่ารายจ่าย"
          count="2000,000.00 บาท"
          :percentage="{ color: 'success'}"
        />
      </li>
      <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
        
        <default-statistics-card
          class="col col-lg-12  "
          title="มูลค่าการซื้อ"
          count="20,000.00 บาท"
          :percentage="{ color: 'success'}"
        /></li>
        
        </ul>
      </div>
    </div>
  </div>
</div>
</div>
        </div>
  </div>
    
      <div class="py-4 container-fluid">
  <div class="card-header">
    <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <div class="col-3 mb-4">
  <label for="choices-year" class="form-label ms-4">Type</label>
  <select
    id="choices-year"
    class="form-select ms-4"
    name="choices-year"
  >
    <option value="choich1">ทั้งหมด</option>
    <option value="choich2">รายรับ</option>
    <option value="choich3">รายจ่าย</option>
    <option value="choich4">รายซื้อ</option>
  </select>
</div>
        <div class="card-header">
          <h5 class="mb-0">รายการรออนุมัติ</h5>
        </div>
        <div class="table-responsive">
          <table id="datatable-search0" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th>วันที่</th>
                <th>รายการ</th>
                <th>ประเภท</th>
                <th>มูลค่า</th>
                <th>สถานะ</th>
                <th>อื่นๆ</th>
                
              </tr>
            </thead>
            <tbody>
              
              <tr>
                <td class="text-sm font-weight-normal">2011/04/25</td>
                <td class="text-sm font-weight-normal">Topic</td>
                <td class="text-sm font-weight-normal">Type</td>
                <td class="text-sm font-weight-normal">100,000.00</td>
                <td class="text-sm font-weight-normal">waiting</td>

               
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  </div>
      </div>
      <div class="py-4 container-fluid">
  <div class="card-header">
    <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">รายการอนุมัติเรียบร้อย</h5>
        </div>
        <div class="table-responsive">
          <table id="datatable-search1" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th>วันที่</th>
                <th>รายการ</th>
                <th>ประเภท</th>
                <th>มูลค่า</th>
                <th>สถานะ</th>
                <th>อื่นๆ</th>
                
              </tr>
            </thead>
            <tbody>
              
              <tr>
                <td class="text-sm font-weight-normal">2011/04/25</td>
                <td class="text-sm font-weight-normal">Topic</td>
                <td class="text-sm font-weight-normal">Type</td>
                <td class="text-sm font-weight-normal">100,000</td>
                <td class="text-sm font-weight-normal">Appoved</td>
               
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  </div>
      </div>
      <div class="py-4 container-fluid">
  <div class="card-header">
    <div class="mt-4 row">
    <div class="col-12">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h5 class="mb-0">รายการไม่อนุมัติ</h5>
        </div>
        <div class="table-responsive">
          <table id="datatable-search2" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th>วันที่</th>
                <th>รายการ</th>
                <th>โปรเจ็ค</th>
                <th>ประเภท</th>
                <th>มูลค่า</th>
                <th>สถานะ</th>
                <th>อื่นๆ</th>
                
              </tr>
            </thead>
            <tbody>
              
              <tr>
                <td class="text-sm font-weight-normal">2011/04/25</td>
                <td class="text-sm font-weight-normal">Topic</td>
                <td class="text-sm font-weight-normal">Type</td>
                <td class="text-sm font-weight-normal">10k</td>
                <td class="text-sm font-weight-normal">not appoved</td>
                <td class="text-sm font-weight-normal">edit</td>
               
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  </div>
      </div>
      <div class="row">
        <default-statistics-card
          class="col col-lg-3  "
          title="รายการรออนุมัติ"
          count="20 รายการ"
          :percentage="{ color: 'success'}"
        />
        <default-statistics-card
          class="col col-lg-3"
          title="รายการอนุมัติ"
          count="10 รายการ"
          :percentage="{ color: 'success'}"
          
        />
        <default-statistics-card
          class="col col-lg-3"
          title="รายการไม่อนุมัติ"
          count="5 รายการ"
          :percentage="{ color: 'success'}"
          
        />
      </div>
      <div class="row">
        <default-statistics-card
          class="col col-lg-4  "
          title="มูลค่ารออนุมัติ"
          count="2,000,000.00 บาท"
          :percentage="{ color: 'success'}"
         
        />
        <default-statistics-card
          class="col col-lg-4"
          title="มูลค่าอนุมัติ"
          count="100,000.00 บาท"
          :percentage="{ color: 'success'}"
          
        />
        <default-statistics-card
          class="col col-lg-4"
          title="มูลค่าไม่อนุมัติ"
          count="5,000.00 บาท"
          :percentage="{ color: 'success'}"
          
        />
      </div>
</template>

<script>

import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import DefaultStatisticsCard from "/src/views/dashboards/components/DefaultStatisticsCard.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import { DataTable } from "simple-datatables";
import noey from "/src/assets/icon/noey.jpg";
import MaterialAvatar from "/src/components/MaterialAvatar.vue";
import marie from "@/assets/img/marie.jpg";

export default {
  name: "ProfileOverview",
  components: { DefaultStatisticsCard,MaterialAvatar
  
 
    
  },
  data() {
    return {
      showMenu: false,
     marie,
      sophie,
      noey,
      simplepic,
      imageUrl: require("@/assets/img/products/product-11.jpg"),
  

    };
  },

  mounted() {

    setNavPills();
    setTooltip(this.$store.state.bootstrap);
     // eslint-disable-next-line no-unused-vars
     const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch1 = new DataTable("#datatable-search1", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch2 = new DataTable("#datatable-search2", {
      searchable: true,
      fixedHeight: true,
    });
  },
};
</script> 
<style scoped>
.img {
 max-width: 100%;
 height: auto;
}

</style>
