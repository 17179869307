<template>
    <nav class="top-0 down-10 navbar navbar-expand-lg position-center z-index-4">
      <div class="container" :class="{ 'px-1': isBlur }">
        <button
          class="shadow-none navbar-toggler ms-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="mt-2 navbar-toggler-icon">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </span>
        </button>
        <div id="navigation" class="pt-3 pb-2 collapse navbar-collapse w-100 py-lg-0">
          <ul class="mx-auto navbar-nav navbar-nav-hover">
            <li class="mx-2 nav-item dropdown dropdown-hover">
              <router-link
                to="/publicpage/homepage"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkModes"
              >
                Home
              </router-link>
            </li>
            <li class="mx-2 nav-item dropdown dropdown-hover">
              <router-link
                to="/publicpage/projectpage"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkModes"
              >
                Project
              </router-link>
            </li>
            <li class="mx-2 nav-item dropdown dropdown-hover">
              <router-link
                to="/publicpage/coworkerpage"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkModes"
              >
                Co-Worker
              </router-link>
            </li>
            <li class="mx-2 nav-item dropdown dropdown-hover">
              <router-link
                to="/publicpage/member"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkModes"
              >
                Member
                <img
                  :src="downArrBlack"
                  alt="down-arrow"
                  class="arrow ms-1 d-block"
                  :class="{ 'd-lg-block': darkMode, 'd-lg-none': !darkMode }"
                />
              </router-link>
            </li>
            <li class="mx-2 nav-item dropdown dropdown-hover">
              <router-link
                to="/publicpage/ContectUs"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkModes"
              >
                Contact
                <img
                  :src="downArrBlack"
                  alt="down-arrow"
                  class="arrow ms-1 d-block"
                  :class="{ 'd-lg-block': darkMode, 'd-lg-none': !darkMode }"
                />
              </router-link>
            </li>
            <li class="mx-2 nav-item dropdown dropdown-hover">
              <router-link
                to="/publicpage/AboutUspage"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                :class="darkModes"
              >
                About Us
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <h3>Member List</h3>
    <div class="row">
        <div class="col-md-3">
        <div class="card card-plain text-center">
          <a href="javascript:;">
            <img class="avatar avatar-xl shadow" :src="img" />
          </a>
          <div class="card-body">
            <h4 class="card-title">Andrew John</h4>
            <h6 class="category text-info text-gradient">Loan Counselor</h6>
            <p class="card-description">
              "Don't walk behind me; I may not lead. Don't walk in front of me; I may not follow. Just walk beside me and be my friend."
            </p>
            <material-button> <router-link to="/publicpage/memberdetail">more</router-link></material-button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-plain text-center">
          <a href="javascript:;">
            <img class="avatar avatar-xl shadow" :src="img" />
          </a>
          <div class="card-body">
            <h4 class="card-title">Andrew John</h4>
            <h6 class="category text-info text-gradient">Loan Counselor</h6>
            <p class="card-description">
              "Don't walk behind me; I may not lead. Don't walk in front of me; I may not follow. Just walk beside me and be my friend."
            </p>
            <material-button> <router-link to="/publicpage/memberdetail">more</router-link></material-button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-plain text-center">
          <a href="javascript:;">
            <img class="avatar avatar-xl shadow" :src="img" />
          </a>
          <div class="card-body">
            <h4 class="card-title">Andrew John</h4>
            <h6 class="category text-info text-gradient">Loan Counselor</h6>
            <p class="card-description">
              "Don't walk behind me; I may not lead. Don't walk in front of me; I may not follow. Just walk beside me and be my friend."
            </p>
            <material-button> <router-link to="/publicpage/memberdetail">more</router-link></material-button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-plain text-center">
          <a href="javascript:;">
            <img class="avatar avatar-xl shadow" :src="img" />
          </a>
          <div class="card-body">
            <h4 class="card-title">Andrew John</h4>
            <h6 class="category text-info text-gradient">Loan Counselor</h6>
            <p class="card-description">
              "Don't walk behind me; I may not lead. Don't walk in front of me; I may not follow. Just walk beside me and be my friend."
            </p>
                <material-button> <router-link to="/publicpage/memberdetail">more</router-link></material-button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapMutations } from "vuex";
  import img from "@/assets/img/marie.jpg";
  import MaterialButton from "@/components/MaterialButton.vue";
  export default {MaterialButton,
    data() {
      return {
        img,
      };
    },
    mounted() {
      this.toggleEveryDisplay();
      this.toggleHideConfig();
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
    };
  </script>
  
  <style scoped>
  /* Card styles */
  .card-plain {
    border: 1px solid #ccc; /* Add border */
    border-radius: 10px; /* Add rounded corners */
    background-color: var(--card-bg);
    padding: 10px; /* Add padding */
    margin: 10px; /* Add margin */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
    display: flex;
  }
  
  .card-title {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
  
  .card-description {
    font-size: 0.875rem;
    color: var(--text-muted);
  }
  
  .avatar-xl {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  </style>
  
  