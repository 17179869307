<template>
    <h2>Shop</h2>
      <div class="row mt-5">
            <div class="col-lg-4 col-md-6">
               <booking-card
               :img="booking1"
               title="Shop Name"
               description="The place is close to Barceloneta Beach and bus stop
               just 2 min by walk and near to 'Naviglio' where you can enjoy the
               main night life in Barcelona."
               location="Barcelona, Spain"
               />
            </div>
            <div class="col-lg-4 col-md-6">
              <booking-card
                :img="booking2"
                title="Shop Name"
                description="The
              place is close to Metro Station and bus stop just 2 min by walk and
              near to 'Naviglio' where you can enjoy the night life in London,
              UK."
                location="London, UK"
              />
            </div> 
            <div class="col-lg-4 col-md-6">
              <booking-card
                :img="booking3"
                title="Shop Name"
                description="The
              place is close to Metro Station and bus stop just 2 min by walk and
              near to 'Naviglio' where you can enjoy the night life in London,
              UK."
                location="Milan, Italy"
              />
            </div>
            <div class="col-lg-4 col-md-6">
              <booking-card
                :img="booking3"
                title="Shop Name"
                description="The
              place is close to Metro Station and bus stop just 2 min by walk and
              near to 'Naviglio' where you can enjoy the night life in London,
              UK."
                location="Milan, Italy"
              />
            </div>
            <div class="col-lg-4 col-md-6">
              <booking-card
                :img="booking2"
                title="Shop Name"
                description="The
              place is close to Metro Station and bus stop just 2 min by walk and
              near to 'Naviglio' where you can enjoy the night life in London,
              UK."
                location="Milan, Italy"
              />
            </div>
            <div class="col-lg-4 col-md-6">
              <booking-card
                :img="booking1"
                title="Shop Name"
                description="The
              place is close to Metro Station and bus stop just 2 min by walk and
              near to 'Naviglio' where you can enjoy the night life in London,
              UK."
                location="Milan, Italy"
              />
            </div>
          </div>
        
    </template>
    
    <script>
  import BookingCard from "/src/views/dashboards/components/BookingCard.vue";
  import booking1 from "/src/assets/img/products/product-1-min.jpg";
  import booking2 from "/src/assets/img/products/product-2-min.jpg";
  import booking3 from "/src/assets/img/products/product-3-min.jpg";

  
  export default {
    components: {
      BookingCard,
    },
    data() {
      return {
        booking1,
        booking2,
        booking3,
      };
    
    },

  };
  </script>
    