<template>
  <div class="py-4 container-fluid">
      <div class="col-lg-6 mt-3">
        <h4>Add Material</h4>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card mt-4" data-animation="true">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <a class="d-block blur-shadow-image">
              <img
                :src="imageUrl"
                alt="img-blur-shadow"
                class="img-fluid shadow border-radius-lg"
              />
            </a>
            <div class="colored-shadow" :style="{ backgroundImage: `url(${imageUrl})` }"></div>
          </div>
          <div class="card-body text-center">
            <div class="mt-n6 mx-auto">
              <input
                ref="fileInput"
                type="file"
                accept="image/*"
                style="display: none;"
                @change="onFileChange"
              />
              <button
                class="btn bg-gradient-success btn-sm mb-0 me-2"
                type="button"
                name="button"
                @click="triggerFileInput"
              >
                Upload Image
              </button>
              <button
                class="btn btn-outline-dark btn-sm mb-0"
                type="button"
                name="button"
              >
                Remove
              </button>
              <h5 class="font-weight-normal mt-4">Name Product</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <form role="form" class="report-form" @submit.prevent="handleSubmit">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Material Information</h5>
            <div class="row mt-4">
                <div class="col-12 col-sm-6 mt-4">
                  <material-input v-model="name_th" variant="dynamic" label="ชื่อ" />
                </div>
                <div class="col-12 col-sm-6 mt-4">
                  <material-input v-model="name_en" variant="dynamic" label="Name" />
                </div>
              
              <div class="row">
              <div class="col-sm-6">
              <label class="mt-0 ms-0">Type</label>
                <select
                  id="choices-type-edit"
                  v-model="type"
                  class="form-control"
                  name="choices-type"
                >
                  <option value="1">Paper</option>
                  <option value="2">Pen</option>
                </select>
              </div>
            <div class="col-6 col-sm-6 mt-4">
                <material-input  id="Order date" v-model="OrderDate" variant="dynamic" type="date" />
              </div>
            </div>
              <div class="row">
                <div class="col-6 col-sm-6 mt-4">
                <material-input id="price" v-model="price" variant="dynamic" label="price" type="number"/>
              </div>
              <div class="col-sm-6">
              <label class="mt-0 ms-0">Unit</label>
                <select
                  id="choices-Unit"
                  v-model="Unit"
                  class="form-control"
                  name="choices-Unit"
                >
                <option value="Choice 1" >ชิ้น</option>
                <option value="Choice 2" >ด้าม</option>
                </select>
              </div>
            
            </div>
            </div>
              <div class="col-sm-12">
                <label class="mt-4 ms-0">Description</label>
                <material-textarea
                  id="Description"
                  v-model="Description"
                  variant="dynamic"
                />
              </div>
              <div class="col-sm-12">
                <label class="mt-2 ms-0">Order By</label>
                  <select
                  id="choices-order-edit"
                  v-model="orderBy"
                  class="form-control"
                  name="choices-order"
                >
                  <option value="Choice 1">Butter</option>
                </select>
               <label class="mt-2 ms-0">Shop</label>
                <select
                  id="choices-shop-edit"
                  v-model="shop"
                  class="form-control"
                  name="choices-shop"
                >
                  <option value="Choice 1" >Black Shop </option>
                  <option value="Choice 2">White Shop</option>
                  <option value="Choice 3">Blue Shop</option>
                  <option value="Choice 4">Orange Shop</option>
                </select>
              </div>
                <material-button
                class="mt-2 mb-0 btn bg-gradient-success ms-lg-auto">Save
                </material-button>
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
  

</template>

<script>
/* eslint-disable prettier/prettier */
import Quill from "quill";
import Choices from "choices.js";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import axios from "axios";

export default {
  name: "EditMat",
  components: { MaterialInput, MaterialTextarea,MaterialButton },
  data() {
    return {
      imageUrl: require("@/assets/img/products/product-11.jpg"),
      name_th:'',
      name_en:'',
      type:'',
      OrderDate:'',
      price:'',
      Unit:'',
      Description:'',
      orderBy:'',
      shop:'',
    };
  },
  mounted() {
    if (document.getElementById("edit-description-edit")) {
      new Quill("#edit-description-edit", {
        theme: "snow",
      });
    }
      this.getChoices("choices-type-edit");
      this.getChoices("choices-shop-edit");
      this.getChoices("choices-order-edit");
      this.getChoices("choices-Unit");

    if (document.getElementById("choices-tags-edit")) {
      var tags = document.getElementById("choices-tags-edit");
      const examples = new Choices(tags, {
        removeItemButton: true,
        allowHTML: true,
      });

      examples.setChoices(
        [
          {
            value: "One",
            label: "Expired",
            disabled: true,
          },
          {
            value: "Two",
            label: "Out of Stock",
            selected: true,
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
          allowHTML: true,
        });
      }
    },
    async handleSubmit() {
      const payload = {
      name_th: this.name_th,
      name_en:this.name_en,
      type_id:1,
      OrderDate:this.OrderDate,
      price:this.price,
      Unit:this.Unit,
      description:this.Description,
      orderBy:this.orderBy,
      shop:this.shop,
      store_id:4
        }
        console.log();
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        };

      const result = await axios.post("https://uat-api.susenginetech.com/api/v1/material/add",payload,config)
      if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        alert("summit success")
      }
    }
    },
  };
</script>
<style>
.text-right {
  text-align: right;
}
.card {
  margin-bottom: 20px;
}
</style>
