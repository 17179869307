<template>
    <h2>Member</h2>

        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Member</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>รูป</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>หน้าที่</th>
                  <th>เข้าระบบล่าสุด</th>
                  <th>สถานะ</th>                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                    <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">
                    <p class="mb-0 text-s">เปมิศา ไตรสุวรรณ์</p>
                    <p class="text-s text-secondary mb-0">john@creative-tim.com</p>
                  </td>
                  <td class="text-sm font-weight-normal">
                    <p class="text-s text-secondary mb-0">โปรแกรมเมอร์</p>
                    <p class="text-s text-secondary mb-0">Programmer</p>

                  </td>
                  <td class="text-sm font-weight-normal">5min</td>
                  <td class="text-sm font-weight-normal"><span class="badge badge-sm badge-success">Online</span></td>               
                </tr>
                <tr>
                    <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">
                    <p class="mb-0 text-s">เปมิศา ไตรสุวรรณ์</p>
                    <p class="text-s text-secondary mb-0">alexa@creative-tim.com</p>
                  </td>
                  <td>
                  <p class="text-s text-secondary mb-0">โปรแกรมเมอร์</p>
                    <p class="text-s text-secondary mb-0">Programmer</p>
                    </td>
                  <td class="text-sm font-weight-normal">5min</td>
                  <td class="text-sm font-weight-normal"><span class="badge badge-sm badge-secondary">Offline</span></td>               
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการสมาชิก</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>รูป</th>
                  <th>ชื่อ</th>
                  <th>นามสกุล</th>
                  <th>หน้าที่</th>
                  <th>ตำแหน่ง</th>
                  <th>สถานะ</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                    <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">ดูแลระบบ</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">เป็นพนักงาน</td>
                  <td class="text-sm font-weight-normal">
                  <a href="/adminpage/memberdocument">more</a>
                </td>
                  
                </tr>
                <tr>
                    <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">ดูแลระบบ</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">ลาออก</td>
                  <td class="text-sm font-weight-normal">
                  <a href="/adminpage/memberdocument">more</a>
                </td>               
                </tr>
                <tr>
                    <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">ดูแลระบบ</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">ไล่ออก</td>
                  <td class="text-sm font-weight-normal">
                  <a href="/adminpage/memberdocument">more</a>
                </td>            
                </tr>
                <tr>
                    <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">ดูแลระบบ</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">ลาพัก</td>
                  <td class="text-sm font-weight-normal">
                  <a href="/adminpage/memberdocument">more</a>
                </td>                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        
  </template>
  
  <script>

  import simplepic from "/src/assets/img/bg-smart-home-1.jpg";
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { DataTable } from "simple-datatables";
  import marie from "/src/assets/img/marie.jpg";
  import MaterialAvatar from "../components/MaterialAvatar.vue";

  export default {
    name: "ProfileOverview",
    components: { MaterialAvatar
    
   
      
    },
    data() {
      return {marie,
        showMenu: false,
       
        simplepic,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
    
  
      };
    },
  
    mounted() {
  
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    const dataTableSearch = new DataTable("#datatable-search0", {
      searchable: true,
      fixedHeight: true,
    });
   // eslint-disable-next-line no-unused-vars
   const dataTableSearch1 = new DataTable("#datatable-search1", {
      searchable: true,
      fixedHeight: true,
    });

    },
  };
  </script> 
  <style scoped>
  .img {
   max-width: 100%;
   height: auto;
  }

  </style>
  