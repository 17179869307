<template>
   <div class="container-fluid">
      <div class="page-header border-radius-xl mt-7">
        
      </div>
      <div class="card card-body mx-3 mx-md-4 mt-n6">
      
          <div class="col-auto">
          </div>
          <div class="col-auto my-auto">
            <h3 class="mb-3 ms-3">Bonus</h3>
            <p class="mb-0 ms-3 font-weight-normal text-sm">description</p>
  <div class="row">
          <default-statistics-card
            title="รายการทั้งหมด"
            count="20,000"
            :percentage="{ color: 'success' }"
          
          />
         
          <default-statistics-card
            title="ราคาสุทธิ"
            count="2,200,000.00 บาท"
            :percentage="{ color: 'success' }"
          
          />
      <default-statistics-card
            class="col col-lg-3"
            title="รายการทั้งหมดของปี"
            count="3,000 "
            :percentage="{ color: 'success'}"
            
          />
         
        </div>
          </div>
      </div></div>
      <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการที่อนุมัติโบนัส 6 เดือน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>6 Month</th>
                  <th>Date</th>
                  <th>Detail</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td
                  ><td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
               
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
             
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
              
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Ashton Cox</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">รายการที่อนุมัติโบนัสรายปี</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>6 Month</th>
                  <th>Date</th>
                  <th>Detail</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
               
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
             
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
              
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">

<default-statistics-card
  class="col col-lg-3  "
  title="รายการทั้งหมด"
  count="2,200"
  :percentage="{ color: 'success' }"

  
/>
<default-statistics-card
  class="col col-lg-4"
  title="ราคาสุทธิทั้งปี"
  count="320,000.00 บาท"
  :percentage="{ color: 'success'}"
  
/>
</div>
<div class="row">
  <h6 class="row">เดือน</h6>
          
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 1"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
            title="เดือน 2"
            count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 3"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 4"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 5"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
          <default-statistics-card
          class="col col-lg-3 row ms-1"
          title="เดือน 6"
          count="200,000.00 บาท"
            :percentage="{ color: 'success'}"
            
          />
</div>
<div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="col-3 mb-4">
  <label for="choices-year" class="form-label ms-4">Month</label>
  <select
    id="choices-year"
    class="form-select ms-4"
    name="choices-year"
  >
  <option value="jan">ม.ค. </option>
<option value="feb">ก.พ. </option>
<option value="mar">มี.ค. </option>
<option value="apr">เม.ย. </option>
<option value="may">พ.ค. </option>
<option value="jun">มิ.ย. </option>
<option value="jul">ก.ค. </option>
<option value="aug">ส.ค. </option>
<option value="sep">ก.ย. </option>
<option value="oct">ต.ค. </option>
<option value="nov">พ.ย. </option>
<option value="dec">ธ.ค.</option>

  </select>
</div>
          <div class="card-header">
            <h5 class="mb-0">รายการที่อนุมัติโบนัส 6 เดือน</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>6 Month</th>
                  <th>Date</th>
                  <th>Detail</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td
                  ><td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
               
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
             
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
              
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Ashton Cox</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="col-3 mb-4">
  <label for="choices-year" class="form-label ms-4">Year</label>
  <select
    id="choices-year"
    class="form-select ms-4"
    name="choices-year"
  >
    <option value="choich1">2567</option>
    <option value="choich2">2566</option>
    <option value="choich3">2565</option>
    <option value="choich4">2564</option>
    <option value="choich5">2563</option>
  </select>
</div>
          <div class="card-header">
            <h5 class="mb-0">รายการที่อนุมัติโบนัสรายปี</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search3" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>6 Month</th>
                  <th>Date</th>
                  <th>Detail</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Tiger Nixon</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                 </td>
                  <td class="text-sm font-weight-normal"> Herrod Chandler</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
               
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Brielle Williamson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
               
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Rhona Davidson</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
             
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">หทัย เรือสุวรรณ</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Airi Satou</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Cedric Kelly</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
              
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Ashton Cox</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                
                  
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">Garrett Winters</td>
                  <td class="text-sm font-weight-normal">30k</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                 
                </tr>
                
               
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </template>
  
  <script>
  import { DataTable } from "simple-datatables";
  import MaterialAvatar from "/src/components/MaterialAvatar.vue";
  import DefaultStatisticsCard from "/src/views/dashboards/components/DefaultStatisticsCard.vue";
  import sophie from "@/assets/img/kal-visuals-square.jpg";

  export default {
    components: {
      MaterialAvatar,
      DefaultStatisticsCard
    },
    data() {
      return {
        sophie,
      };
    },
    
    mounted() {
    
  
      // eslint-disable-next-line no-unused-vars
      const dataTableSearch = new DataTable("#datatable-search0", {
        searchable: true,
        fixedHeight: true,
      });
      // eslint-disable-next-line no-unused-vars
      const dataTableSearch1 = new DataTable("#datatable-search1", {
        searchable: true,
        fixedHeight: true,
      });
       // eslint-disable-next-line no-unused-vars
       const dataTableSearch2 = new DataTable("#datatable-search2", {
        searchable: true,
        fixedHeight: true,
      });
      // eslint-disable-next-line no-unused-vars
      const dataTableSearch3 = new DataTable("#datatable-search3", {
        searchable: true,
        fixedHeight: true,
      });
    },
  };

  </script>
  