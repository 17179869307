<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3 class="mb-4">Material Details</h3>
            <div class="row">
              <div class="col-xl-5 col-lg-6 text-center">
                <img
                  class="w-100 border-radius-lg shadow-lg mx-auto"
                  src="/src/assets/img/products/product-details-1.jpg"
                  alt="chair"
                />
                <div
                  id="product-gallery"
                  class="pt-2 mt-4 my-gallery d-flex gap-4"
                >
                  <a
                    v-for="(image, key) in images"
                    :key="key"
                    :href="image.largeURL"
                    :data-pswp-width="image.width"
                    :data-pswp-height="image.height"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      :src="image.thumbnailURL"
                      alt=""
                      class="shadow w-100 min-height-100 max-height-100 border-radius-lg"
                    />
                  </a>
                </div>
              </div>
              <div class="mx-auto col-lg-5">
                <h4 class="mt-4 mt-lg-0">{{detail.name_th}}Minimal Bar Stool</h4>
                
                <h6 class="mt-3 mb-0">Price</h6>
                <h5>{{detail.price}} </h5>
                <span class="badge badge-success">In Stock</span>
                <span class="badge badge-danger ms-2">None</span>
                <br />
                <label class="mt-4">Information</label>
                <ul>
                  <li><strong>Material Name : </strong> {{detail.name_th}},{{detail.name_en}}</li>
                  <li><strong>Type: </strong>{{detail.type_id}} </li>
                  <li><strong>Price : </strong> {{detail.price}} </li>
                  <li><strong>Date Buy  : </strong> 4 / 03 /2024 </li>
                  <li><strong>Order Buy : </strong> Staff </li>
                  <li><strong>Description : </strong> {{detail.description}} </li>
                  <li><strong>Shop : </strong> {{detail.store_id}} </li>
                </ul>
                <material-botton class="mb-3 ms-2 btn btn-success" ><i class="material-icons text-white position-relative text-md pe-2">edit</i>Edit</material-botton>

              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Choices from "choices.js";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import productDetails1 from "/src/assets/img/products/product-details-1.jpg";
import productDetails2 from "/src/assets/img/products/product-details-2.jpg";
import productDetails3 from "/src/assets/img/products/product-details-3.jpg";
import productDetails4 from "/src/assets/img/products/product-details-4.jpg";
import productDetails5 from "/src/assets/img/products/product-details-5.jpg";
import MaterialBotton from "/src/components/MaterialButton.vue";
import axios from 'axios';

export default {
  name: "ProductPage",
  components: {  },
  data() {
    return {
      productDetails1,
      productDetails2,
      productDetails3,
      productDetails4,
      productDetails5,
      MaterialBotton,
      detail: {
      name_th : "",
      name_en : "",
      price : "",
      description : "",
      purchase_date : "",
      serial_no : "",
      store_id : "",
      owner : "",
      type : ""
},
      images: [
        {
          largeURL: productDetails2,
          thumbnailURL: productDetails2,
          width: 500,
          height: 600,
        },
        {
          largeURL: productDetails3,
          thumbnailURL: productDetails3,
          width: 500,
          height: 600,
        },
        {
          largeURL: productDetails4,
          thumbnailURL: productDetails4,
          width: 500,
          height: 600,
        },
        {
          largeURL: productDetails5,
          thumbnailURL: productDetails5,
          width: 500,
          height: 600,
        },
      ],
    };
  },
  mounted() {
    this.getChoices("choices-quantity");
    this.getChoices("choices-material");
    this.getChoices("choices-colors");
    if (!this.lightbox) {
      this.lightbox = new PhotoSwipeLightbox({
        gallery: "#" + "product-gallery",
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      this.lightbox.init();
    }
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox.destroy();
      this.lightbox = null;
    }
  },
  methods: {
    async getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
          itemSelectText: "",
          allowHTML: true,
        });
      }
      const result = await axios.get("https://uat-api.susenginetech.com/api/v1/material/"+this.$route.query.id)
      if (result.data.isError == true){
        alert(result.data.errorMessage)
        }
      else{
        this.detail=result.data.data
        console.log(this.detail);
        
      }
    },
  },
};
</script>
