<template>
  <div class="col-lg-6 mt-3">
  <h3>Add Coworker</h3>
</div>
    <!-- Upload Section -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card p-3">
          <div
            id="productImg"
            action="/file-upload"
            class="form-control border dropzone"
          ></div>
        </div>
      </div>
    </div>
  <div id="company-info" class="card mt-4">
    <div class="card-header">
      <h5>Company</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12">
          <material-input
            id="companyName"
            variant="static"
            label="Name"
            placeholder="Name Company"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="companyEmail"
            type="email"
            variant="static"
            label="Email"
            placeholder="example@email.com"
          />
        </div>
        <div class="col-6">
          <material-input
            id="companyPhoneNumber"
            type="phone"
            variant="static"
            label="Phone Number"
            placeholder="+40 745 765 439"
          />
        </div>
        <div class="col-6">
          <material-input
            id="vatNumber"
            type="text"
            variant="static"
            label="เลขที่ภาษี"
            placeholder="40745765439"
          />
        </div>
        <div class="col-6">
          <material-input
            id="Location"
            type="text"
            variant="static"
            label="Location"
            placeholder="map"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="locationNo"
            type="text"
            variant="static"
            label="Present Address"
            placeholder="40/532"
          /></div>
        <div class="col-6">
          <label>District</label>
          <select
            id="choices-district1"
            class="form-control"
            name="choices-district"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
        </div>
        <div class="col-6">
          <label>Amphur</label>
          <select
            id="choices-amphur1"
            class="form-control"
            name="choices-amphur"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
        </div>
        <div class="col-6">
          <label >Province</label>
          <select
            id="choices-province1"
            class="form-control"
            name="choices-province"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
        </div>
        <div >
          <material-input
            id="Postcode"
            type="text"
            variant="static"
            label="Postcode"
            placeholder="12122"
          />
        </div>
        </div>
      </div>
        <div class="d-flex justify-content-end mb-4">
      <material-button
        color="success"
        variant="gradient"
        class="mb-0"
        type="button"
        name="button"
      >
        Submit
      </material-button>
    </div>
       
  </div>

</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Dropzone from "dropzone"; // Make sure Dropzone is properly imported

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialInput,
  },
  data() {
    return {
      imageUrl: '',
    };
  },
  mounted() {
    const initializeChoices = (id) => {
      const element = document.getElementById(id);
      if (element) {
        new Choices(element, { allowHTML: true });
      }
    };

    initializeChoices("choices-amphur");
    initializeChoices("choices-province");
    initializeChoices("choices-district");
    initializeChoices("choices-amphur1");
    initializeChoices("choices-province1");
    initializeChoices("choices-district1");
    initializeChoices("choices-gender");
    initializeChoices("choices-role");
    initializeChoices("choices-position");
    initializeChoices("choices-level");

    const initializeDateChoices = (id, start, end, defaultValue) => {
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          new Choices(element, { allowHTML: true });
        }, 1);

        for (let i = start; i <= end; i++) {
          const option = document.createElement("OPTION");
          option.text = i;
          option.value = i;
          if (i === defaultValue) {
            option.selected = true;
          }
          element.options.add(option);
        }
      }
    };

    const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthElement = document.getElementById("choices-month");
    if (monthElement) {
      setTimeout(() => {
        new Choices(monthElement, { allowHTML: true });
      }, 1);

      monthArray.forEach((month, index) => {
        const option = document.createElement("OPTION");
        option.text = month;
        option.value = index + 1;
        if (index === 1) {
          option.selected = true;
        }
        monthElement.options.add(option);
      });
    }

    initializeDateChoices("choices-day", 1, 31, 1);
    initializeDateChoices("choices-year", 1900, new Date().getFullYear(), new Date().getFullYear());

    // Initialize Dropzone
    const myDropzone = new Dropzone("#productImg");
    myDropzone.on("addedfile", (file) => {
      console.log(`File added: ${file.name}`);
    });
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
    }
  },
};
</script>

<style scoped>
/* Scoped styles for the component */
.flex-container {
  display: flex;
  justify-content: flex-end;
}

.dropzone {
  border: 2px dashed #007bff;
  border-radius: 0.5rem;
  padding: 20px;
  background-color: #f9f9f9;
}

.card {
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
