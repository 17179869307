<template>
    <div class="col-lg-6 mt-3">
      <h2>Setting</h2>
    </div>
  <div class="container">
    <div class="card">
      <div class="mt-4 ms-3 row">
        <!-- Image Upload Section -->
        <div class="col-lg-4">
          <div class="card mt-4" data-animation="true">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <a class="d-block blur-shadow-image">
                <img
                  :src="imageUrl"
                  alt="img-blur-shadow"
                  class="img-fluid shadow border-radius-lg"
                />
              </a>
              <div class="colored-shadow" :style="{ backgroundImage: `url(${imageUrl})` }"></div>
            </div>
            <div class="card-body text-center">
              <input
                type="file"
                ref="fileInput"
                @change="onFileChange"
                accept="image/*"
                style="display: none;"
              />
            </div>
          </div>
        </div>
          <div class="col-md-8">
            <h4 class="mb-3">ข้อมูลบริษัท</h4>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><strong>ชื่อ:</strong>บริษัท ซัซ เอ็นจิ้น เทค</li>
              <li class="list-group-item"><strong>Name:</strong> Suset Co,.Ltd</li>
              <li class="list-group-item"><strong>AKA:</strong> Suset</li>
              <li class="list-group-item"><strong>เลขที่ภาษี :</strong> 40745765439</li>
              <li class="list-group-item"><strong>Address:</strong> 17/4 Moo.5, Bamroongrat Road, Tambon Pibulsongkram, Amphur Muang, Bangkok, 10400</li>
              <li class="list-group-item"><strong>Phone Number:</strong> 089-9999999</li>
              <li class="list-group-item"><strong>email:</strong> xxxx@gmail.com</li>
            </ul>
          </div>
        </div>
      </div>
    </div>    
<form>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="mt-4 ms-3 row">
        <!-- Image Upload Section -->
        <div class="col-lg-4">
          <div class="card mt-4" data-animation="true">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <a class="d-block blur-shadow-image">
                <img
                  :src="imageUrl"
                  alt="img-blur-shadow"
                  class="img-fluid shadow border-radius-lg"
                />
              </a>
              <div class="colored-shadow" :style="{ backgroundImage: `url(${imageUrl})` }"></div>
            </div>
            <div class="card-body text-center">
              <input
                type="file"
                ref="fileInput"
                @change="onFileChange"
                accept="image/*"
                style="display: none;"
              />
              <button class="btn bg-gradient-success btn-sm mb-0 me-2" type="button" @click="triggerFileInput">
                Upload Image
              </button>
              <button class="btn btn-outline-dark btn-sm mb-0" type="button" @click="removeImage">
                Remove
              </button>
            </div>
          </div>
        </div>

        <!-- Form Section -->
        <div class="col-lg-8 mt-4 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">แก้ไขข้อมูลบริษัท</h5>              
              <div class="row mt-4">
                <div class="col-12">
                  <material-input id="thname" v-model="thname" variant="dynamic" label="ชื่อภาษาไทย" />
                </div>
                <div class="col-12">
                  <material-input id="engname" v-model="engname" variant="dynamic" label="Name" />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-6">
                  <material-input id="AKA" v-model="AKA" variant="dynamic" label="AKA" />
                </div>
                <div class="col-6">
                  <material-input id="tax" v-model="tax" variant="dynamic" label="เลขผู้เสียภาษา" />
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-6">
                  <material-input
                    id="locationNo"
                    v-model="locationNo"
                    type="text"
                    variant="static"
                    label="ที่อยู่ปัจจุบัน"
                    placeholder="40/532"
                  />
                </div>
                <div class="col-6">
                    <label>เขต</label>
          <select
            id="choices-district"
            class="form-control"
            name="choices-district"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
          </div>
        <div class="col-6 mt-4">
          <label>อำเภอ</label>
          <select
            id="choices-amphur"
            class="form-control"
            name="choices-amphur"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
                </div>
                <div class="col-6 mt-4">
                    <label >จังหวัด</label>
          <select
            id="choices-province"
            class="form-control"
            name="choices-province"
          >
            <option value="choich1">1</option>
            <option value="choich2">2</option>
            <option value="choich3">3</option>
            <option value="choich4">4</option>
            <option value="choich5">5</option>
          </select>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-6">
                  <material-input
                    id="Postcode"
                    v-model="postcode"
                    type="text"
                    variant="static"
                    label="รหัสไปรษณี"
                    placeholder="12122"
                  />
                </div>
                <div class="col-6">
                  <material-input
                    id="Phonenumber"
                    v-model="phonenumber"
                    type="text"
                    variant="static"
                    label="เบอร์โทร"
                    placeholder="087654321"
                  />
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <material-input id="email" v-model="email" variant="dynamic" label="อีเมลล์" />
                </div>
              </div>

              <div class="col-sm-12 mt-4">
                <label for="information">ช่องทางการโซเชียล</label>
                <material-textarea
                  id="information"
                  v-model="information"
                  variant="dynamic"
                  placeholder="Say a few words about who you are or what you're working on."
                />
              </div>

              <div class="row mt-4">
                <div class="col-6">
                  <material-input id="headperson" v-model="headperson" variant="dynamic" label="ผู้มีอำนาจปัจจุบัน" />
                </div>
                <div class="col-6">
                  <material-input id="shareholder" v-model="shareholder" variant="dynamic" label="ผู้ถือหุ้น" />
                </div>
              </div>
              <button type="submit" class="mt-4 btn bg-gradient-success ms-lg-auto me-lg-0 me-auto">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<form>
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">เอกสารบริษัท</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>เลือก</th>
                  <th>รายการ</th>
                  <th>วันที่</th>
                  <th>ข้อมูล</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                    <td class="text-sm font-weight-normal">
                        <material-checkbox id="checkboxId">เลือก</material-checkbox>
                    </td>
                    <td class="text-sm font-weight-normal">ภพ.20</td>
                    <td class="text-sm font-weight-normal">2011/04/25</td>
                    <td class="text-sm font-weight-normal">
                        <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
                        <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>PDF
                        </button>
                    </td>
                    <td>
                        <button class="mb-0 ms-3 btn btn-danger"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal-1"> 
                                <i class="material-icons text-white position-relative text-md pe-2">delete</i>
                        Delete
                        </button>
                        <!-- Modal -->
                        <div class="modal fade"
                            id="exampleModal-1" 
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel-1"
                            aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel-1">Confirm For Delete</h5> 
                                <button type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                Are you sure you want to delete this data? This action cannot be undone.
                            </div>
                            <div class="modal-footer justify-content-between">
                                <button type="button"
                                        class="btn btn-dark"
                                        data-bs-dismiss="modal">
                                Cancel 
                                </button>
                                <button type="button"
                                        class="btn btn-danger">
                                Confirm
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </td>                 
                    </tr>

                <tr>
                    <td class="text-sm font-weight-normal"> <material-checkbox id="checkboxId">เลือก</material-checkbox></td>
                    <td class="text-sm font-weight-normal">หนังสือรับรอง</td>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal"><button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>PDF </button> </td> 
              <td><button class="mb-0 ms-3 btn btn-danger"
                    variant="gradient"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    <i class="material-icons text-white position-relative text-md pe-2">delete</i>
                    Delete
                    </button>
            <!-- Modal -->
            <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm For Delete</h5>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    Are you sure you want to delete this data? This action cannot be undone.
                  </div>
                  <div class="modal-footer justify-content-between">
                    <button type="button"
                            class="btn btn-dark"
                            data-bs-dismiss="modal">
                      Cancle
                    </button>
                    <button type="button"
                            class="btn btn-danger">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div></td>           
            
           
                </tr>       
              </tbody>
            </table>
            <DIV class="row ">
                  <div class="col-3">
            <material-input
              id="Title"
              variant="static"
              label="รายการ"
              placeholder="หนังสือรับรอง"
            />
          </div>
          <div class="col-3">
            <material-input
            type="date"
              id="dateupload"
              variant="static"
              label="วันที่"
              placeholder="23/3/67"
            />
          </div>
          <div class="col-3">
            <material-input
              id="file"
              variant="static"
              label="แนบไฟล์"
              placeholder="ไฟล์"
            />
          </div>
                  <material-button class="col-2 bt btn-success"><i class="material-icons text-white position-relative text-md pe-2"
                    >add</i>Add</material-button>
              </DIV>

              </div>
            </div>
          </div>
          </div>
        </div>
</form>
<form>
    <div class="card-header mt-4 ">
        <h4>Public page</h4>
        <material-switch class="ms-1">ปิดปรับปรุงเว็บ</material-switch>
        <h5>หน้าหลัก</h5>
      <p class="styled-paragraph"> Body text for your whole article or post. Well put in some lorem ipsum to
      show how a filled-out page might look:

      Excepteur efficient emerging, minim veniam anim aute carefully curated
      Ginza conversation exquisite perfect nostrud nisi intricate Content. Qui
      international first-class nulla ut. Punctual adipisicing, essential lovely
      queen tempor eiusmod irure. Exclusive izakaya charming Scandinavian
      impeccable aute quality of life soft power pariatur Melbourne occaecat
      discerning. Qui wardrobe aliquip, et Porter destination Toto remarkable
      officia Helsinki excepteur Basset hound. Zürich sleepy perfect consectetur.

      Exquisite sophisticated iconic cutting-edge laborum deserunt Addis Ababa
      esse bureaux cupidatat id minim. Sharp classic the best commodo nostrud
      delightful. Conversation aute Rochester id. Qui sunt remarkable deserunt
      intricate airport handsome K-pop excepteur classic esse Asia-Pacific
      laboris.
    </p>
    <div class="row">
                <div class="col-sm-12">
                  <label class="mt-4 ms-0">Description</label>
                  <material-textarea
                    id="information"
                    variant="dynamic"
                    placeholder="Say a few words about who you are or what you're working on."
                  />
                </div>
              </div>
              <div class=" mt-2">
              <material-button class="col-2 bt btn-success">Update</material-button>
            </div>
              <div class="card-header">
            <h5 class="mb-0">ประวัติการแก้ไข</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>ตำแหน่ง</th>
                  <th>ข้อความ</th>
                  <th>โดย</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">introduction</td>
                  <td class="text-sm font-weight-normal">Exquisite sophisticated iconic cutting-edge laborum deserunt Addis Ababa</td>
                  <td class="text-sm font-weight-normal"><a href="/adminpage/memberdocument">เปมิศา</a></td>
                  <td class="text-sm font-weight-normal"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</form>
<from>
  <div class="card-header mt-4 ">
    <h4>วันลา</h4>
    <material-switch>ลากิจ</material-switch>
    <material-switch>ลาป่วย</material-switch>
    <material-switch>ลาพักร้อน</material-switch>
    <material-switch>ลาคลอด</material-switch>
    <p>ลากิจ ปีละ/วัน<material-input></material-input></p>
    <p>ลาป่วย ปีละ/วัน<material-input></material-input></p>
    <p>ลาพักร้อน ปีละ/วัน<material-input></material-input></p>
    <p>ลาคลอด ปีละ/วัน<material-input></material-input></p>
    <div class="card-header">
            <h6 class="mb-0">ประวัติการแก้ไข</h6>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>รายการ</th>
                  <th>เดือนละ</th>
                  <th>โดย</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">ลากิจ</td>
                  <td class="text-sm font-weight-normal">10</td>
                  <td class="text-sm font-weight-normal"><a href="/adminpage/memberdocument">เปมิศา</a></td>
                  <td class="text-sm font-weight-normal"></td>
                </tr>
              </tbody>
            </table>
          </div>
  <div class="card-header">
            <h6 class="mb-0">รายชื่อพนักงาน</h6>
          </div>
          <div class="table-responsive">
            <table id="datatable-search4" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>โปรไฟล์</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>ตำแหน่ง</th>
                  <th>เลเวล</th>
                  <th>ลากิจ</th>
                  <th>ลาป่วย</th>
                  <th>ลาพักร้อน</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">1</td>
                  <td class="text-sm font-weight-normal"><material-input  variant="dynamic" label="ลากิจ" /></td>
                  <td class="text-sm font-weight-normal"><material-input  variant="dynamic" label="ลาป่วย" /></td>
                  <td class="text-sm font-weight-normal"><material-input  variant="dynamic" label="ลาพักร้อน" /></td>
                  <td class="text-sm font-weight-normal"><material-button class="bt btn-success"><i class="material-icons text-white position-relative text-md pe-2"
                    >edit</i>Edit</material-button><material-button class="bt btn-danger ms-2">Reset</material-button></td>
                  <td class="text-sm font-weight-normal"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</from>
<form>
<div class="card-header mt-4 ">
    <h4>OT / BONUS</h4>
    <material-switch>ระบบOT</material-switch>
    <material-switch>ระบบBonus</material-switch>
    <h5>OT</h5>
    <h6>Fulltime</h6>
    <p>จำกัด OT เดือนละ/ชั่วโมง <material-input></material-input></p>
    <h6>วิธีคิดโอทีพนักงานรายเดือน</h6>
    <ul>
        <li>สูตรการคำนวณ: (เงินเดือน / 30 /ชั่วโมงงานปกติ) X (1.5 หรือ 3 เท่า) X (จํานวนชั่วโมงที่ทำโอที)</li>
        <p>ตัวอย่างวิธีการคำนวณ: กรณีได้ค่าล่วงเวลา 1.5 เท่า และทําโอที 4 ชั่วโมง
            เงิน OT ที่จะได้รับในวันนั้น = (15,000 /30/8) X 1.5 X 4 = 375 บาท</p>
    </ul>
    <div class="row">
    <h6>เลือกค่า(แล้วแต่กำหนด) <material-input id="type" variant="dynamic" label="1.5" /></h6>
    </div>
    <div class=" mt-2">
        <material-button class="col-2 bt btn-success">submit</material-button>
     </div>
     <h6>Parttime</h6>
     <p>จำกัด OT เดือนละ/ชั่วโมง <material-input></material-input></p>
     <h6>วิธีคิดโอทีพนักงานรายวัน</h6>
    <ul>
        <li> สูตรการคำนวณ: (ค่าจ้างต่อวัน / ชั่วโมงงานปกติ) X (1.5 หรือ 3 เท่า) X (จํานวนชั่วโมงที่ทำโอที)</li>
        <p>ตัวอย่างวิธีการคำนวณ: กรณีได้ค่าล่วงเวลา 3 เท่า และทำโอที 3 ชั่วโมง
            เงิน OT ที่จะได้รับในวันนั้น = (400 / 8) X 3 X 3 = 450 บาท</p>
    </ul>
     <div class="row">
      <h6>เลือกค่า(แล้วแต่กำหนด) <material-input id="type" variant="dynamic" label="1.5" /></h6>
    </div>
    <div class=" mt-2">
        <material-button class="col-2 bt btn-success">submit</material-button>
     </div>
     <div class="card-header">
            <h6 class="mb-0">ประวัติการแก้ไข</h6>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>วันที่</th>
                  <th>รายการ</th>
                  <th>OTเดือนละ</th>
                  <th>เท่า</th>
                  <th>โดย</th>
                  <th>อื่นๆ</th>    
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td class="text-sm font-weight-normal">2011/04/25</td>
                  <td class="text-sm font-weight-normal">ปรับโอที</td>
                  <td class="text-sm font-weight-normal">10ชม.</td>
                  <td class="text-sm font-weight-normal">3</td>
                  <td class="text-sm font-weight-normal"><a href="/adminpage/memberdocument">เปมิศา</a></td>
                  <td class="text-sm font-weight-normal"></td>
                </tr>
              </tbody>
            </table>
          </div>        
     <h5>Bonus</h5>
     <h6>สูตรวิธีคิดโบนัส</h6>
    <ul>
        <li> สูตรการคำนวณ: ค่าจ้างพื้นฐาน x อัตราเดือนของ โบนัส x คะแนนผลประเมิน = เงินโบนัส </li>
        <p>ตัวอย่างวิธีการคำนวณ: 15,000 x 0.5 x 1.5 = 11,250 บาท</p>
    </ul>
    <div class="d-flex align-items-center">
    <div class="col-3">
      <material-input id="finalsalary" variant="dynamic" label="เงินเดือนสุดท้าย" />  
    </div>
    <p class="mx-2">-</p> <!-- ใช้ mx-2 เพื่อเพิ่มระยะห่างด้านซ้ายและขวา -->
    <div class="col-3">
      <material-input id="firstsalary" variant="dynamic" label="เงินเดือนเริ่มต้น" />
    </div>
    <p class="mx-2">=</p>
    <div class="col-3">
        <material-input id="evaluation-score" variant="dynamic" label="อัตราเดือนของโบนัส" />
    </div>
    <div class="col-5">
      <material-button class="col-3 bt btn-success"><i class="material-icons text-white position-relative text-md pe-2"
        >add</i>Add</material-button>
    </div>
</div>
<div class="card-header">
            <h6 class="mb-0">รายการอัตราโบนัส</h6>
          </div>
          <div class="table-responsive">
            <table id="datatable-search3" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>เลือก</th>
                  <th>เริ่มที่</th>
                  <th>สุดท้ายที่</th>
                  <th>อัตรา(เท่า)</th>
                  <th>บันทึกวันที่</th>
                  <th>อื่นๆ</th>                  
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td class="text-sm font-weight-normal"> <material-checkbox id="checkboxId">เลือก</material-checkbox></td>
                  <td class="text-sm font-weight-normal">10,000</td>
                  <td class="text-sm font-weight-normal">15,000</td>
                  <td class="text-sm font-weight-normal">0.5.</td>
                  <td class="text-sm font-weight-normal">13/3/67</td>
                  <td class="text-sm font-weight-normal"><material-button class="bt btn-danger"> <i class="material-icons text-white position-relative text-md pe-2">delete</i>Delete</material-button></td>
                  <td class="text-sm font-weight-normal"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-header">
            <h6 class="mb-0">รายชื่อพนักงาน</h6>
          </div>
          <div class="table-responsive">
            <table id="datatable-search4" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>โปรไฟล์</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>ตำแหน่ง</th>
                  <th>เลเวล</th>
                  <th>เงินเดือน</th>
                  <th>Bonus</th>
                  <th>OT</th>
                  <th>อื่นๆ</th>                  
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">1</td>
                  <td class="text-sm font-weight-normal">19,000</td>
                  <td class="text-sm font-weight-normal"><material-input id="bonus" variant="dynamic" label="เงินโบนัส" /></td>
                  <td class="text-sm font-weight-normal"><material-input id="OT" variant="dynamic" label="OT" /></td>
                  <td class="text-sm font-weight-normal"><material-button class="bt btn-success"><i class="material-icons text-white position-relative text-md pe-2"
                    >edit</i>Edit</material-button>
                    <material-button class="bt btn-danger ms-2">Reset</material-button></td> 
                  <td class="text-sm font-weight-normal"></td>
                </tr>
              </tbody>
            </table>
          </div>
</div>
</form>
<form>
  <div class="card-header mt-4 ">
    <div class="card-header">
            <h6 class="mb-0">กำหนดเงินเดือน</h6>
          </div>
          <div class="table-responsive">
            <table id="datatable-search5" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>โปรไฟล์</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>หน้าที่</th>
                  <th>ตำแหน่ง</th>
                  <th>เลเวล</th>
                  <th>เงินเดือน</th>
                  <th>อื่นๆ</th>
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">แอดมิน</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">1</td>
                  <td class="text-sm font-weight-normal"><material-input id="salary" variant="dynamic" label="เงินเดือน" /></td>                  
                  <td class="text-sm font-weight-normal"><material-button class="bt btn-success">
                    <i class="material-icons text-white position-relative text-md pe-2">edit</i>Edit</material-button></td>
                </tr>
              </tbody>
            </table>
          </div>
</div>
<div class="card-header">
            <h6 class="mb-0">ประวัติการแก้ไข</h6>
          <div class="table-responsive">
            <table id="datatable-search6" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>โปรไฟล์</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>หน้าที่</th>
                  <th>ตำแหน่ง</th>
                  <th>เลเวล</th>
                  <th>เงินเดือน</th>
                  <th>ผู้แก้ไข</th>
                  <th>อื่นๆ</th>
                  
                </tr>
              </thead>
              <tbody>               
                <tr>
                  <td  class="text-sm font-weight-normal ">
                  <material-avatar
                    :img="marie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                    </td>
                  <td class="text-sm font-weight-normal">เปมิศา ไตรสุวรรณ์</td>
                  <td class="text-sm font-weight-normal">แอดมิน</td>
                  <td class="text-sm font-weight-normal">โปรแกรมเมอร์</td>
                  <td class="text-sm font-weight-normal">1</td>
                  <td class="text-sm font-weight-normal">20,000</td>
                  <td class="text-sm font-weight-normal">อชิระ</td>
                  <td class="text-sm font-weight-normal"></td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
</form>



  </template>
  
  <script>
  /* eslint-disable prettier/prettier */
  import Quill from "quill";
  import Choices from "choices.js";
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialTextarea from "@/components/MaterialTextarea.vue";
  import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
  import MaterialButton from "/src/components/MaterialButton.vue";
  import { DataTable } from "simple-datatables";
  import MaterialSwitch from "@/components/MaterialSwitch.vue";
  import Suset from "/src/assets/icon/SUSET.jpg";
  import marie from "/src/assets/img/marie.jpg";
  import MaterialAvatar from "../components/MaterialAvatar.vue";

  export default {
    name: "EditMat",
    components: { MaterialInput, MaterialTextarea ,MaterialCheckbox,MaterialButton,MaterialSwitch,MaterialAvatar},
    data() {
      return {
        imageUrl: require("/src/assets/icon/SUSET.jpg"),
        Suset,
        marie,
      };
    },
    mounted() {
        // eslint-disable-next-line no-unused-vars
        const dataTableSearch = new DataTable("#datatable-search0", {
          searchable: true,
          fixedHeight: true,
        });
         // eslint-disable-next-line no-unused-vars
         const dataTableSearch1 = new DataTable("#datatable-search1", {
          searchable: true,
          fixedHeight: true,
        });
        // eslint-disable-next-line no-unused-vars
        const dataTableSearch2 = new DataTable("#datatable-search2", {
          searchable: true,
          fixedHeight: true,
        });
        // eslint-disable-next-line no-unused-vars
        const dataTableSearch3 = new DataTable("#datatable-search3", {
          searchable: true,
          fixedHeight: true,
        });
        // eslint-disable-next-line no-unused-vars
        const dataTableSearch4 = new DataTable("#datatable-search4", {
          searchable: true,
          fixedHeight: true,
        });
        // eslint-disable-next-line no-unused-vars
        const dataTableSearch5 = new DataTable("#datatable-search5", {
          searchable: true,
          fixedHeight: true,
        });
        // eslint-disable-next-line no-unused-vars
        const dataTableSearch6 = new DataTable("#datatable-search6", {
          searchable: true,
          fixedHeight: true,
        });
      if (document.getElementById("edit-description-edit")) {
        new Quill("#edit-description-edit", {
          theme: "snow",
        });
      }
      this.getChoices("choices-amphur");
      this.getChoices("choices-province");
      this.getChoices("choices-district");
  
      if (document.getElementById("choices-tags-edit")) {
        var tags = document.getElementById("choices-tags-edit");
        const examples = new Choices(tags, {
          removeItemButton: true,
          allowHTML: true,
        });
  
        examples.setChoices(
          [
            {
              value: "One",
              label: "Expired",
              disabled: true,
            },
            {
              value: "Two",
              label: "Out of Stock",
              selected: true,
            },
          ],
          "value",
          "label",
          false
        );
        
      }
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      getChoices(id) {
        if (document.getElementById(id)) {
          var element = document.getElementById(id);
          return new Choices(element, {
            searchEnabled: false,
            allowHTML: true,
          });
        }
      },
    },
  };
  </script>
  
  <style>
  .text-right {
    text-align: right;
  }
  .card {
    margin-bottom: 20px;
  }
  .styled-paragraph {
      border: 1px solid #6d6d6d; /* Creates a solid border */
      padding: 20px; /* Adds space inside the border */
      margin: 20px; /* Adds space outside the border */
      background-color: #f9f9f9; /* Background color for the paragraph */
      font-family: Arial, sans-serif; /* Font styling */
      line-height: 1.6; /* Spacing between lines */
      border-radius: 8px; /* Rounded corners */
    }
  </style>
  