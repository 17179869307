<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <h4>Purches</h4>
        </div>
              </div>
            </div>
        <div class="mt-5 ms-5 col-lg-11 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Purches Information</h5>
              <div class="row mt-4">
                <div class="col-12 col-sm-12">
                  <material-input id="Product name" variant="dynamic" label="Product Name" />
                </div>
                <div class="row">
                <div class="col-sm-12">
                <label class="mt-0 ms-0">Type</label>
                  <select
                    id="choices-type-edit"
                    class="form-control"
                    name="choices-type"
                  >
                    <option value="Choice 1" selected>Material</option>
                    <option value="Choice 2">Eqiupmant</option>
                   </select>
                </div>
                <div class="col-12 col-sm-4 mt-4">
                  <material-input id="Price" variant="dynamic" label="Price" />
                </div>
              <div class="col-6 col-sm-4 mt-4">
                  <material-input id="Amount" variant="dynamic" label="Amount" />
                </div>
                <div class="col-6 col-sm-4 ">
                    <label class="mt-0 ms-0">Unit</label>
                  <select
                    id="choices-unit"
                    class="form-control"
                    name="choices-type"
                  >
                    <option value="Choice 1" selected>Material</option>
                    <option value="Choice 2">Eqiupmant</option>
                   </select>
                </div>
                <div class="col-6 col-sm-12 mt-2">
                  <material-input id="discount" variant="dynamic" label="Discount" />
                </div>
                <div class="col-6 col-sm-12 mt-2">
                  <material-input id="Date" variant="dynamic" label="Date" />
                </div>
              </div>
              </div>
                <div class="col-sm-12">
                  <label class="mt-4 ms-0">Description</label>
                  <material-textarea
                    id="information"
                    variant="dynamic"
                    placeholder="Say a few words about who you are or what you're working on."
                  />
                </div>
                <div class="mt-2 mb-2 row">
      
             
        <input
          type="file"
          ref="fileInput"
          @change="onFileChange"
          style="display: none;"
        />
        <button @click="triggerFileInput" class="btn btn-primary">
          Attach File
        </button>
    </div>
            </div>
        </div>
      </div>
  </template>
  
  <script>
  /* eslint-disable prettier/prettier */
  import Quill from "quill";
  import Choices from "choices.js";
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialTextarea from "@/components/MaterialTextarea.vue";

  export default {
    name: "EditMat",
    components: {
      MaterialInput,
      MaterialTextarea
    },
    data() {
      return {
        imageUrl: require("@/assets/img/products/product-11.jpg"),
      };
    },
    mounted() {
      if (document.getElementById("edit-description-edit")) {
        new Quill("#edit-description-edit", {
          theme: "snow",
        });
      }
      this.getChoices("choices-type-edit");
      this.getChoices("choices-unit");

      if (document.getElementById("choices-tags-edit")) {
        var tags = document.getElementById("choices-tags-edit");
        const examples = new Choices(tags, {
          removeItemButton: true,
          allowHTML: true,
        });

        examples.setChoices(
          [
            {
              value: "One",
              label: "Expired",
              disabled: true,
            },
            {
              value: "Two",
              label: "Out of Stock",
              selected: true,
            },
          ],
          "value",
          "label",
          false
        );
      }
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      getChoices(id) {
        if (document.getElementById(id)) {
          var element = document.getElementById(id);
          return new Choices(element, {
            searchEnabled: false,
            allowHTML: true,
          });
        }
      },
    },
  };
</script>

  
  <style>
  .text-right {
    text-align: right;
  }
  .card {
    margin-bottom: 20px;
  }
  </style>
  