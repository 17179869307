<template>
  <div class="mb-4 card">
    <div class="pb-0 card-header">
      <h6>{{ title }}</h6>
    </div>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th
                v-for="(header, index) of headers"
                :key="index"
                :class="index >= 1 ? 'text-center ps-2' : ''"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                { title: listTitle, values, image, }, index
              ) in lists"
              :key="index"
            >
              <td>
                <div class="px-3 py-1 d-flex">
                  <material-avatar
                    :img="image"
                    class="me-3"
                    circular
                    alt="avatar image"
                  />
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ listTitle }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ values[0] }}
                </p>
              </td>
              <td class="text-sm align-middle">
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ values[1] }}
                </p>
              </td>
              <td class="align-middle text-center">
                <div
                  class="text-center px-3 py-1 d-flex justify-content-center align-items-center"
                >
                  <p class="mb-0 text-sm font-weight-bold">
                    {{ values[2] }}
                  </p>
                  <td><material-button class="bt btn-info">invite</material-button></td>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "OrdersListCard",
  components: {
    MaterialAvatar,
    MaterialButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    lists: {
      type: Array,
      default: () => [],
      values: Array,
      title: String,
      order: String,
      info: String,
      image: String,
      icon: String,
    },
  },
};
</script>
