<template>
  <h2>Edit Project</h2>
    <div class="container-fluid">
      <div class="page-header border-radius-xl mt-7"></div>
      <div class="card card-body mx-3 mx-md-4 mt-n6">
        <div class="row gx-4">
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">Edit Project</h5>
            </div>
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-lg-4">
            <div class="card mt-4" data-animation="true">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <a class="d-block blur-shadow-image">
                  <img :src="imageUrl" alt="img-blur-shadow" class="img-fluid shadow border-radius-lg" />
                </a>
                <div class="colored-shadow" :style="{ backgroundImage: `url(${imageUrl})` }"></div>
              </div>
              <div class="card-body text-center">
                <div class="mt-n6 mx-auto">
                  <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none;" />
                  <button class="btn bg-gradient-success btn-sm mb-0 me-2" type="button" @click="triggerFileInput" name="button">
                    Upload Image
                  </button>
                  <button class="btn btn-outline-dark btn-sm mb-0" type="button" name="button">
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="ms-5 col-lg-11 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h6 class="font-weight-bolder">Document</h6>
              <div class="col-lg-3 col-sm-2 mt-sm-0 ">
            <default-info-card
              icon="examples/Icon/Document.vue"
              title="Document1"
              description="Belong Interactive"
            />
          </div>
            </div>
          </div>
    </div>
    <div class="container mt-2">
  <div class="row">
    <div class="col-12 col-xl-12 mb-2">
      <div class="card card-plain h-100">
        <div class="card-header p-4 pb-0">
          <h5 class="mb-3">Project Information</h5>
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-12 mb-3">
              <material-input
                id="projectName"
                variant="static"
                label="Project Name"
                placeholder="Alec"
                class="form-control"
              />
            </div>
           
          <label>Type</label>
          <select
            id="choices-Type"
            class="form-control"
            name="choices-Type"
          >
            <option value="choich1">Job</option>
            <option value="choich2">Company</option>
          </select>
       
                <div class="col-sm-12">
                  <label class="mt-4 ms-0">Description</label>
                  <material-textarea
                    id="information"
                    variant="dynamic"
                    placeholder="Say a few words about who you are or what you're working on."
                  />
                </div>
                <div class="col-12 mb-3">
              <material-input
                id="stardatetwork"
                variant="static"
                label="Start Work"
                placeholder="12/3/67"
                class="form-control"
              />
            </div>
            <div class="col-12 mb-3">
              <material-input
                id="Enddatetwork"
                variant="static"
                label="End Work"
                placeholder="12/3/67"
                class="form-control"
              />
            </div>
            <div class="col-12 mb-3">
              <material-input
                id="overprice"
                variant="static"
                label="price"
                placeholder="1200k"
                class="form-control"
              />
            </div>
   
        <material-input
          id="location"
          type="text"
          variant="static"
          label="Location"
          placeholder="Google map"
        />
          </div>
        </div>
      </div>
    </div>
      </div>
        </div>
      </div>
      <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Director</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search0" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Level</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Director</td>
                  <td class="text-sm font-weight-normal">2</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Director</td>
                  <td class="text-sm font-weight-normal">2</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>

                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Director</td>
                  <td class="text-sm font-weight-normal">2</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Manager</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search1" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Level</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Manager</td>
                  <td class="text-sm font-weight-normal">2</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Manager</td>
                  <td class="text-sm font-weight-normal">1</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>

                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Manager</td>
                  <td class="text-sm font-weight-normal">3</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
        <div class="py-4 container-fluid">
    <div class="card-header">
      <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Staff</h5>
          </div>
          <div class="table-responsive">
            <table id="datatable-search2" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Level</th>
                  <th>Status</th>
                  
                </tr>
              </thead>
              <tbody>
                
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Staff</td>
                  <td class="text-sm font-weight-normal">2</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>
                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Staff</td>
                  <td class="text-sm font-weight-normal">2</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>

                <tr>
                  <td  class="text-sm font-weight-normal">
                  <material-avatar
                    :img="sophie"
                    class="me-1"
                    circular
                    alt="avatar image"
                  />
                
                  </td>
                  <td class="text-sm font-weight-normal">เปมิศา</td>
                  <td class="text-sm font-weight-normal">Staff</td>
                  <td class="text-sm font-weight-normal">2</td>
                  <td><material-button class="bt btn-info">Invite</material-button>
                    <material-button class="ms-2 bt btn-info">Uninvite</material-button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
        </div>
   
    <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Delete Project</h5>
      <p class="text-sm mb-0">
        Once you delete your account, there is no goin g back. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <material-switch id="deleteAccount" name="Delete Account" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Confirm</span
          >
          <span class="text-xs d-block">I want to delete my account.</span>
        </div>
      </div>
      <material-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        >Delete Account</material-button
      >
    </div>
  </div>
  <div class="row">
    <material-button class="col-11  btn btn-info mb-3">Save</material-button>
    </div>
  </template>
  
  <script>
  import sophie from "/src/assets/img/marie.jpg";
  import MaterialButton from "/src/components/MaterialButton.vue";
  import DefaultInfoCard from "/src/examples/Cards/DefaultInfoCard.vue";
  import MaterialInput from "/src/components/MaterialInput.vue";
  import MaterialTextarea from "/src/components/MaterialTextarea.vue";
  import { DataTable } from "simple-datatables";
  import MaterialAvatar from "/src/components/MaterialAvatar.vue";
  import * as Choices from "choices.js";





  export default {

    components: {
      MaterialButton,
      MaterialInput,
      MaterialTextarea,
      DefaultInfoCard,
      MaterialAvatar

    },
    data() {
      return {
        showMenu: false,
        sophie,
        imageUrl: require("@/assets/img/products/product-11.jpg"),
      }
    },
    mounted() {
      const initializeChoices = (id) => {
      const element = document.getElementById(id);
      if (element) {
        new Choices(element, { allowHTML: true });
      }
    };


    initializeChoices("choices-Type");
// eslint-disable-next-line no-unused-vars
const dataTableSearch = new DataTable("#datatable-search0", {
  searchable: true,
  fixedHeight: true,
});
// eslint-disable-next-line no-unused-vars
const dataTableSearch1 = new DataTable("#datatable-search1", {
  searchable: true,
  fixedHeight: true,
});
// eslint-disable-next-line no-unused-vars
const dataTableSearch2 = new DataTable("#datatable-search2", {
  searchable: true,
  fixedHeight: true,
});
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
    },
  };
  </script>
  
  <style scoped>
  .img {
    max-width: 100%;
    height: auto;
  }
  .information {
    font-size: 14px;
    color: #555454;
    margin: 30px 0;
    line-height: 30px;
  }
  </style>
  